<template>
  <transition name="slide-left">
    <QCard v-if="appBannerDisplayed" class="app-banner mobile-screens">
      <header>
        <span class="text-primary">
          {{ $t(data.title) }}
          <QBtn v-if="canClose" class="app-banner-close-btn" flat dense size="sm" icon="close" @click="closeAppBanner" />
        </span>
      </header>
      <QItem class="app-banner-content">
        <QItemSection avatar>
          <Avatar size="50px" :picture="data.picture" />
        </QItemSection>

        <QItemSection>
          <QItemLabel caption>
            {{ $t(data.subtitle) }}
          </QItemLabel>
          <QItemLabel caption>
            <QIcon v-for="star in data.rating" :key="star" name="icon-extra-star" color="primary-medium" />
            <QIcon name="icon-extra-half-star" color="primary-medium" />
          </QItemLabel>
        </QItemSection>
        <QItemSection side>
          <QItemLabel caption>
            <QBtn
              :label="$t(data.action)"
              color="white"
              text-color="primary"
              dense
              no-caps
              class="app-banner-cta q-px-sm"
              @click="download(data.ios, data.android)"
            />
          </QItemLabel>
        </QItemSection>
      </QItem>
    </QCard>
  </transition>
</template>

<script>
  import { StoresService } from "@core/plugins/mobile";
  import { JavascriptHelpers } from "@core/helpers";
  import {
    USER_CLOSED_APP_BANNER_COOKIE,
    APP_RATING,
    DEVICE_OS,
    ETAB_APP_ID_ANDROID,
    ETAB_APP_ID_IOS,
    EXTRA_APP_ID_ANDROID,
    EXTRA_APP_ID_IOS,
  } from "@core/constants";

  const candidateLogo = require("@core/assets/img/logos/logo_extra_candidat.png");
  const etabLogo = require("@core/assets/img/logos/logo_etab.png");

  const candidate = {
    title: "CORE.COMPONENTS.APPBANNER.@CANDIDATE_TITLE",
    picture: candidateLogo,
    subtitle: "CORE.COMPONENTS.APPBANNER.@CANDIDATE_SUBTITLE",
    rating: APP_RATING,
    action: "CORE.COMPONENTS.APPBANNER.@ACTION",
    ios: EXTRA_APP_ID_IOS,
    android: EXTRA_APP_ID_ANDROID,
  };

  const etab = {
    title: "CORE.COMPONENTS.APPBANNER.@ETAB_TITLE",
    picture: etabLogo,
    subtitle: "CORE.COMPONENTS.APPBANNER.@ETAB_SUBTITLE",
    rating: APP_RATING,
    action: "CORE.COMPONENTS.APPBANNER.@ACTION",
    ios: ETAB_APP_ID_IOS,
    android: ETAB_APP_ID_ANDROID,
  };

  export default {
    props: {
      canClose: {
        type: Boolean,
        default: false,
      },
      candidate: {
        type: Boolean,
        default: false,
      },
      etab: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        appBannerDisplayed: true,
      };
    },
    computed: {
      data() {
        return this.candidate ? candidate : etab;
      },
    },
    created() {
      this.APP_RATING = APP_RATING;
      if (this.canClose) {
        this.initializeAppBannerDisplay();
      }
    },
    methods: {
      initializeAppBannerDisplay() {
        const userClosedAppBanner = this.$q.cookies.get(USER_CLOSED_APP_BANNER_COOKIE);
        this.appBannerDisplayed = !userClosedAppBanner;
      },
      closeAppBanner() {
        try {
          this.appBannerDisplayed = false;
          this.$q.cookies.set(USER_CLOSED_APP_BANNER_COOKIE, true, {
            expires: "30d",
          });
        } catch (error) {
          throw error;
        }
      },
      download(ios, android) {
        const operatingSystem = JavascriptHelpers.getMobileOperatingSystem();
        if (operatingSystem === DEVICE_OS.IOS) {
          StoresService.openAppStoreLink(ios);
        } else if (operatingSystem === DEVICE_OS.ANDROID) {
          StoresService.openAndroidStoreLink(android);
        }
      },
    },
  };
</script>

<style lang="scss">
  .app-banner {
    border-radius: 0;
    background-color: $grey-2;
    z-index: 2;

    > header {
      padding-top: 5px;
      span {
        padding-left: 10px;
      }
      .app-banner-close-btn {
        position: absolute;
        right: 5px;
      }
    }
    .app-banner-content {
      padding: 5px;
      > * {
        padding: 0 5px;
      }
    }

    .app-banner-cta {
      border-radius: 10px;
    }
  }
</style>
