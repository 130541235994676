import { EMAIL_EXTRACADABRA_SUPPORT, EMAIL_EXTRACADABRA_CRA, EMAIL_CANDIDATES_SUPPORT, EMAIL_EXTRACADABRA_DPO } from "@core/constants";
import { NativeCommunicationManager } from "@core/managers/native-communication.manager";
import { i18n } from "@core/plugins/i18n";

export class ContactManager {
  static contactEtabSupport({ subject } = {}) {
    const to = EMAIL_EXTRACADABRA_SUPPORT;
    const translatedSubject = subject ? i18n.global.t(subject) : "";

    NativeCommunicationManager.sendEmail({ to, subject: translatedSubject });
  }

  static contactExtracadabraDpo({ subject } = {}) {
    const to = EMAIL_EXTRACADABRA_DPO;
    const translatedSubject = subject ? i18n.global.t(subject) : "";

    NativeCommunicationManager.sendEmail({ to, subject: translatedSubject });
  }

  static contactEtabCRA({ subject } = {}) {
    const to = EMAIL_EXTRACADABRA_CRA;
    const translatedSubject = subject ? i18n.global.t(subject) : "";

    NativeCommunicationManager.sendEmail({ to, subject: translatedSubject });
  }

  static contactCandidateSupport({ subject } = {}) {
    const to = EMAIL_CANDIDATES_SUPPORT;
    const translatedSubject = subject ? i18n.global.t(subject) : "";

    NativeCommunicationManager.sendEmail({ to, subject: translatedSubject });
  }
}
