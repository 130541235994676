<template>
  <div class="job-offers-search-component">
    <!-- JobsSearchForm must stay first level child of job-offers-search-component  -->
    <!-- in order to allow the position:sticky in the overflow context -->
    <JobsSearchForm
      :forced-criterias="forcedCriterias"
      :should-stick="true"
      :allow-new-search="allowNewSearch"
      :dense="dense"
      @submit="submitSearch"
    />

    <div v-if="allowNewSearch" class="text-right cta-new-search">
      <router-link :to="searchRoute">
        {{ $t("JOBS.THEMATIC.@NEW_SEARCH") }}
      </router-link>
    </div>
    <div>
      <section v-show="searchResults.getTotal() === 0">
        <JobsSearchNoResults :is-region="isRegion" :label="label" :typeform-link="typeformLink" />
      </section>

      <section v-show="searchResults.getTotal() > 0" class="job-offers-list-container">
        <h2 class="text-h2">
          {{ $tc("JOBS.LABELS.@LIST_TOTAL", searchResults.getTotal()) }}
        </h2>
        <JobOffersListComponent :job-offers-list="searchResults.getJobsOffersList()" />
      </section>

      <section v-if="canLoadMore" style="text-align: center">
        <QBtn :label="$t('JOBS.LABELS.@DISPLAY_MORE')" color="secondary" class="text-bold" @click="loadMore" />
      </section>
    </div>
  </div>
</template>

<script>
import { NotificationsManager } from "@core/managers/notifications.manager";
import { QueryHelpers } from "@core/helpers";
import { JobsSearchCriteriasModel } from "@core/models";
import { JOBS_ROUTES } from "@jobs/config";
import { ACTION_JOBS_SEARCH_ACTION, GETTER_JOBS_SEARCH_RESULTS_GET_ALL } from "@jobs/store/types";

import JobsSearchForm from "@jobs/components/search/JobsSearch.form.vue";
import JobsSearchNoResults from "@jobs/components/search/JobsSearchNoResults.component.vue";
import JobOffersListComponent from "@jobs/components/offers/JobOffersList.component.vue";

const DEFAULT_ROWS_PER_PAGE = 12;

export default {
  name: "JobOffersSearch",
  components: {
    JobsSearchNoResults,
    JobsSearchForm,
    JobOffersListComponent,
  },
  props: {
    forcedCriterias: {
      type: Object,
      default: () => new JobsSearchCriteriasModel(),
    },
    allowNewSearch: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    isRegion: {
      type: Boolean,
      default: false,
    },
    typeformLink: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    isLoading() {
      return this.$_isGlobalLoadingActive;
    },
    searchResults() {
      return this.$store.getters[GETTER_JOBS_SEARCH_RESULTS_GET_ALL];
    },
    canLoadMore() {
      return this.searchResults.getTotal() > this.rowsPerPage;
    },
    page() {
      return this.$route.query.page || 1;
    },
    rowsPerPage() {
      return parseInt(this.$route.query.nb, 10) || DEFAULT_ROWS_PER_PAGE;
    },
    selectedQualifications() {
      return QueryHelpers.mergeQueryParams(this.$route.query.q, this.forcedCriterias.getQualificationsIds());
    },
    selectedContracts() {
      return QueryHelpers.mergeQueryParams(this.$route.query.c, this.forcedCriterias.getContractsCodes());
    },
    selectedPostalCodes() {
      return QueryHelpers.mergeQueryParams(this.$route.query.cp, this.forcedCriterias.getPostalCodes());
    },
  },
  watch: {
    $route(to, from) {
      this.search();
    },
  },
  created() {
    this.initialSearch();
    this.initLink();
  },
  methods: {
    initLink() {
      const query = this.$route.query;
      const utmQueryParams = Object.keys(query).reduce((obj, key) => {
        if (key.includes("utm")) {
          obj[key] = query[key];
        }
        return obj;
      }, {});

      this.searchRoute = { name: JOBS_ROUTES.public.search.name, query: utmQueryParams };
    },
    querySearch(query, params) {
      return this.$router.push({ name: this.$route.name, query, params }).catch((suppressedError) => {});
    },
    initialSearch() {
      if (!process.env.SERVER) {
        this.search();
      }
    },
    submitSearch({ selectedQualifications = [], selectedContracts = [], selectedPostalCodes = [] } = {}) {
      const originalQuery = this.$route.query;

      const query = {
        ...originalQuery,
        q: selectedQualifications,
        c: selectedContracts,
        cp: selectedPostalCodes,
        page: 1, // reset pagination
        nb: DEFAULT_ROWS_PER_PAGE, // reset the number of rows
      };

      this.querySearch(query);
    },
    loadMore() {
      const originalQuery = this.$route.query;
      const nb = this.rowsPerPage + DEFAULT_ROWS_PER_PAGE;

      const query = {
        ...originalQuery,
        nb,
      };
      // do not scroll to top on load more
      const params = { doNotScrollTop: true };
      // do not create an entry in the browser history
      return this.$router.replace({ name: this.$route.name, query, params }).catch((suppressedError) => {});
    },
    async search() {
      try {
        this.$_startGlobalLoading();
        const page = this.page;
        const rowsPerPage = this.rowsPerPage;
        const qualification = this.selectedQualifications;
        const contract = this.selectedContracts;
        const postalCode = this.selectedPostalCodes;

        await this.$store.dispatch(ACTION_JOBS_SEARCH_ACTION, { page, rowsPerPage, qualification, contract, postalCode });
      } catch (error) {
        NotificationsManager.error();
      } finally {
        this.$_stopGlobalLoading();
      }
    },
  },
};
</script>

<style lang="scss">
.job-offers-search-component {
  .cta-new-search {
    display: flex;
    justify-content: center;
    @media screen and (min-width: 870px) {
      display: block;
      margin-top: 30px;
    }
    a {
      text-decoration: none;
      font-weight: bold;
      padding: 15px;
      border: 1px solid $secondary;
      background-color: transparent;
      border-radius: 6px;
      color: $secondary;
      width: 100%;
      text-align: center;
      @media screen and (min-width: 870px) {
        width: auto;
      }
      &:hover {
        color: #fff;
        background-color: $secondary;
      }
    }
  }
  > div {
    > section {
      margin-top: 25px;
    }
  }
  .job-offers-list-container {
    width: 100%;
    max-width: 1225px;
    @media screen and (max-width: 1270px) {
      max-width: 908px;
    }
    @media screen and (max-width: 1024px) {
      max-width: 820px;
    }
    @media screen and (max-width: 870px) {
      max-width: 700px;
    }
    @media screen and (max-width: $breakpoint-xs) {
      padding-top: 50px;
      margin-bottom: 20px;
    }
  }
}
</style>
