<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="faq-sections-container">
    <dl ref="faqSectionsListRef" class="faq-sections-list row">
      <dt v-for="(faq, index) in faqData" :key="faq.question" class="faq-sections-item col-xs-12">
        <details @click="toggleFAQ($event, index)">
          <summary class="faq-sections-item__title">
            {{ faq.question }}
            <i :class="[isOpen && activeEl === index ? 'minus' : 'plus']" />
          </summary>
          <div class="faq-sections-item__content" v-html="faq.answer" />
        </details>
      </dt>
    </dl>
  </div>
</template>

<script setup>
  import { useMeta } from "quasar";
  import { ref, computed } from "vue";

  const props = defineProps({
    faqData: {
      type: Array,
      default: () => [],
    },
  });

  const faqSectionsListRef = ref(null);
  const activeEl = ref("");
  const isOpen = ref(false);

  function toggleFAQ(event, index) {
    if (!faqSectionsListRef.value) {
      return;
    }

    faqSectionsListRef.value.querySelectorAll("details").forEach((detail) => {
      if (detail !== event.currentTarget) {
        detail.removeAttribute("open");
      }
    });

    if (activeEl.value === index) {
      isOpen.value = !isOpen.value;
    } else {
      isOpen.value = true;
    }
    activeEl.value = index;
  }

  const generateJsonLd = computed(() => {
    const jsonLd = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: props.faqData.map((faq) => ({
        "@type": "Question",
        name: faq.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: faq.answer,
        },
      })),
    };

    return JSON.stringify(jsonLd);
  });

  useMeta({
    script: {
      ldJson: {
        type: "application/ld+json",
        innerHTML: generateJsonLd.value,
      },
    },
  });
</script>

<style lang="scss">
  .faq-sections-list {
    font-family: "poppins";
    padding: 0;

    @media screen and (min-width: $breakpoint-sm-max) {
      max-width: 645px;
    }

    a {
      // avoid default blue link
      color: inherit;
    }

    .faq-sections-item {
      width: 100%;
    }

    dt {
      summary {
        cursor: pointer;
        font-size: 18px;
        font-weight: 800;
        color: #fff;
        border: none;
        outline: none;
        transition: max-height 0.6s ease-in-out;
        background-color: $extra-pink;
        padding: 45px 32px 45px 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (min-width: $breakpoint-sm-max) {
          padding: 40px 32px 40px 18px;
        }
        i {
          min-width: 24px;
          height: 24px;
          display: block;
          margin-left: 15px;
          &.plus {
            background-image: url("https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/plus.png");
          }
          &.minus {
            background-image: url("https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/minus.png");
          }
        }
      }
      details {
        margin-bottom: 12px;

        p {
          margin: 0;
        }

        .faq-sections-item__content {
          margin: 0;
          padding: 36px 32px;
          border: 1px solid $extra-lighten-pink;
          transition: max-height 0.6s ease-in-out;
          max-height: 0;
          font-size: 14px;
          margin-bottom: 12px;
          text-align: left;
          background-color: #fff;
        }
        &[open] {
          .faq-sections-item__title {
            background-color: $extra-lighten-pink;
            color: $extra-pink;
            margin-bottom: 0;
          }
          .faq-sections-item__content {
            max-height: 1000px;
            overflow: hidden;
            margin-bottom: 12px;
            font-style: italic;
            color: $extra-pink;
          }
        }
      }
    }
  }
</style>
