<template>
  <div class="base-footer-component">
    <QFooter>
      <QToolbar color="primary">
        <slot />
      </QToolbar>
    </QFooter>
  </div>
</template>

<script>
  export default {
    name: "BaseFooter",
  };
</script>

<style lang="scss">
  .base-footer-component {
    .q-toolbar {
      padding: 30px;
      text-align: left;
      display: flex;
      align-items: flex-start;
      line-height: 30px;
      font-size: 0.9em;

      @media screen and (max-width: $breakpoint-sm) {
        flex-direction: column;
        text-align: center;
      }
      section {
        padding: 0 15px;

        p {
          margin-left: 4px; // align with btn
          line-height: 1.5;
        }

        > h4,
        .text-h4 {
          font-weight: bold;
        }

        > ul {
          list-style: none;
          padding: 0;

          > li {
            a {
              color: white;

              &:visited {
                color: white;
              }
            }

            .q-btn {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
</style>
