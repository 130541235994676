<template>
  <div class="verbatim-landing">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <h2 v-html="verbatimData.title " />
    <div v-for="verbatim in verbatimData.verbatim" :key="verbatim.author" class="verbatim-item row" :class="verbatim.position">
      <div class="verbatim-item-content col-md-7" :class="{'with-avatar col-xs-12': verbatim.avatar != undefined, 'col-xs-9' : verbatim.avatar === undefined}">
        <img v-if="verbatim.logo" :src="verbatim.logo" :alt="verbatim.logo_alt" />
        <img v-if="verbatim.avatar" :src="verbatim.avatar" :alt="verbatim.logo_alt" class="avatar" />
        <div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="verbatim.text" />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="verbatim.author" />
        </div>
      </div>
      <div v-if="verbatim.image_desktop" class="verbatim-item-image col-xs-3 col-md-5">
        <img class="desktop" :src="verbatim.image_desktop" :alt="verbatim.image_alt" />
        <img class="mobile" :src="verbatim.image_mobile" :alt="verbatim.image_alt" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    verbatimData: {
      type: Object,
      default:() => ({}),
    },
  },

  
};
</script>

<style lang="scss">
.verbatim-landing{
  padding: 0;
  margin-bottom: 60px;
  @media screen and (min-width: $breakpoint-sm-max) {
    padding: 30px 120px 50px 120px;
    border-bottom: 1px solid #cecece;
    margin-bottom: 0;
  }
  @media screen and (min-width: $breakpoint-lg-min) {
    padding: 30px 270px 50px 237px;
  }
  h2{
    color: $secondary;
    padding: 0 12px;
    text-align: center;
    margin-bottom: 40px;
    @media screen and (min-width: $breakpoint-sm-max) {
      padding: 0;
    }
  }
  .verbatim-item{
    display: flex;
    &.right{
      flex-direction: row-reverse;
      .verbatim-item-content{
        padding-left: 25px;
        @media screen and (min-width: $breakpoint-sm-max) {
          padding-left: 70px;
        }
        @media screen and (min-width: $breakpoint-lg-min) {
          padding-left: 25px;
        }
      }
    }
    &.left{
      .verbatim-item-content{
        padding-left: 14px;
      }
      .with-avatar{
        padding: 0 14px;
        img{
          margin-right: 15px;
        }
      }
    }
    &.right{
      .with-avatar{
        padding: 0 14px;
        flex-direction: row-reverse;
      }
    }
    figure{
      margin:0;
      blockquote{
        margin: 0;
        p{
          font-family: 'PoppinsItalic';
          font-size: 14px;
          margin-bottom: 6px;
          max-width: 90%;
          line-height: 24px;
        }
      }
    }
    .verbatim-item-image{
      display: flex;
      justify-content: center;
      align-items: center;
      .mobile{
        display: flex;
        @media screen and (min-width: $breakpoint-sm-max) {
          display: none;
        }
      }
      .desktop{
        display: none;
        @media screen and (min-width: $breakpoint-sm-max) {
          display: flex;
        }
      }
    }
    &-content{
      &.with-avatar{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        flex-direction: inherit;
        margin-bottom: 40px;
        @media screen and (min-width: $breakpoint-sm-max) {
          padding-left: 70px;
          align-items: center;
          flex-wrap: nowrap;
        }
      .avatar{
          height: 99px;
          @media screen and (min-width: $breakpoint-sm-max) {
            margin-right: 15px;
          }
        }
      }
    }
  }

}

</style>
