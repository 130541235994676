<template>
  <BaseLandingHeader :cta="cta" @open-modal-form="emitOpenForm" />
</template>

<script>
  import BaseLandingHeader from "@core/components/layouts/BaseLanding.header.vue";

  export default {
    components: {
      BaseLandingHeader,
    },
    props: {
      cta: {
        type: Object,
        default: () => ({}),
      },
    },
    emits: ["openModalForm"],
    methods: {
      emitOpenForm(url) {
        this.$emit("openModalForm", url);
      },
    },
  };
</script>
