// https://quasar-framework.org/components/notify.html
import { Notify } from "quasar";
import { i18n } from "@core/plugins/i18n";
import { NOTIFICATION_DELAY, NOTIFICATION_TYPES } from "@core/constants";
import { ContactManager } from "@core/managers/contact.manager";

/**
 * Quasar.Notify config override
 */
const defaultConfig = {
  position: "bottom-right",
  timeout: NOTIFICATION_DELAY,
  actions: [{ icon: "close", color: "white" }],
};

const activeMessages = [];

/**
 * Offers several static methods to notify the user with a toaster
 * Wraps Quasar.Notify
 * [Quasar doc]{ @link https://quasar-framework.org/components/notify.html }
 */
export class NotificationsManager {
  /**
   * Quasar.Notify wrapper with automatic translation of strings
   * @static
   * @param {*} config - destructured config object
   * @param {string} config.message - the main notification message
   * @param {string} [config.detail] - the message subtitle
   * @param {*} [config.options] - Quasar.Notify options
   * @property {boolean} [config.options.force] - The notification will happen even if duplicate
   * @returns {function} function to dismiss the notification
   */
  static create({ message, detail, options = {} } = {}) {
    const config = Object.assign({}, defaultConfig, options);

    const messageAlreadyActive = NotificationsManager.isMessageAlreadyActive({ message, options });

    if (options.force || !messageAlreadyActive) {
      return Notify.create({
        progress: true,
        message: NotificationsManager.translate(message),
        caption: NotificationsManager.translate(detail),
        ...config,
      });
    }
    return null;
  }

  /**
   * Shortcut to Notify with a success color
   * @static
   * @param {*} config - destructured config object
   * @param {string} config.message - the main notification message
   * @param {string} [config.detail] - the message subtitle
   * @param {Object} [config.options] - Quasar.Notify options
   * @property {boolean} [config.options.force] - The notification will happen even if duplicate
   * @returns {function} function to dismiss the notification
   */
  static success({ message, detail, options = {} } = {}) {
    options.color = "positive";
    return NotificationsManager.create({ message, detail, options });
  }

  /**
   * Shortcut to Notify with a error color
   * @static
   * @param {*} config - destructured config object
   * @param {string} config.message - the main notification message
   * @param {string} [config.detail] - the message subtitle
   * @param {Object} [config.options] - Quasar.Notify options
   * @property {boolean} [config.options.force] - The notification will happen even if duplicate
   * @returns {function} function to dismiss the notification
   */
  static error({ message, detail, options = {} } = {}) {
    options.color = "negative";
    options.group = options.group || NOTIFICATION_TYPES.ERROR;
    if (!message && !detail) {
      message = "ERRORS.@SOMETHING_WENT_WRONG";
    }
    return NotificationsManager.create({ message, detail, options });
  }

  /**
   * Shortcut to Notify with error color and a mail button
   * that will open email to etab support
   * @static
   * @param {Object} { subject = {String} }
   * @returns {function} function to dismiss the notification
   */
  static contactEtabSupport({
    message = "ERRORS.@SOMETHING_WENT_WRONG_CONTACT_US",
    subject = "EMAILS.@SOMETHING_WENT_WRONG_ETAB",
    color = "negative",
    options = {},
  } = {}) {
    options.group = options.group || NOTIFICATION_TYPES.ERROR;
    return NotificationsManager.create({
      message,
      options: {
        color,
        closeBtn: false,
        actions: [
          {
            icon: "icon-f-mail",
            color: "white",
            handler: () => {
              ContactManager.contactEtabSupport({ subject });
            },
          },
        ],
        ...options,
      },
    });
  }

  /**
   * Shortcut to Notify with a info color
   * @static
   * @param {*} config - destructured config object
   * @param {string} config.message - the main notification message
   * @param {string} [config.detail] - the message subtitle
   * @param {Object} [config.options] - Quasar.Notify options
   * @property {boolean} [config.options.force] - The notification will happen even if duplicate
   * @returns {function} function to dismiss the notification
   */
  static info({ message, detail, options = {} } = {}) {
    options.color = "extra-blue";
    return NotificationsManager.create({ message, detail, options });
  }

  /**
   * Shortcut to Notify with a warning color
   * @static
   * @param {*} config - destructured config object
   * @param {string} config.message - the main notification message
   * @param {string} [config.detail] - the message subtitle
   * @param {Object} [config.options] - Quasar.Notify options
   * @property {boolean} [config.options.force] - The notification will happen even if duplicate
   * @returns {function} function to dismiss the notification
   */
  static warning({ message, detail, options = {} } = {}) {
    options.color = "warning";
    return NotificationsManager.create({ message, detail, options });
  }

  /**
   * Wrap i18n service for internal use
   * @static
   * @param {string} key - the translation key
   * @param {Object} [values] - the optional values
   * @returns {string} translated value
   */
  static translate(key, values) {
    if (!key) {
      return;
    }
    return i18n.global.t(key, values);
  }

  /**
   * Check for duplicates (based on title)
   * This method will maintain a auto-cleaned log of active messages
   * @static
   * @param {string} message - the title of the message
   * @returns {boolean} - whether or not the message already exists in the log
   */
  static isMessageAlreadyActive({ message, options = {} } = {}) {
    const activeIndex = activeMessages.indexOf(message);

    if (activeIndex === -1) {
      // if the message is not active, make it so
      activeMessages.push(message);

      const dispose = setTimeout(() => {
        // after a while, the message is no longer active
        const index = activeMessages.indexOf(message);
        if (index !== -1) {
          activeMessages.splice(index, 1);
        }
        // we dispose the timeout inside its own callback
        clearTimeout(dispose);
        // we assume the message is no longer active after timeout + animation (roughly 1s)
      }, (options.timeout || NOTIFICATION_DELAY) + 1000);
    }

    return activeIndex !== -1;
  }
}
