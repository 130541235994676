export class NativeCommunicationManager {
  static phone({ phone } = {}) {
    window.location.href = `tel:${phone}`;
  }

  static sms({ phone } = {}) {
    window.location.href = `sms:${phone}`;
  }

  static sendEmail({ to, subject = "" } = {}) {
    window.location.href = `mailto:${to}?subject=${subject}`;
  }
}
