<template>
  <div class="list-pictos-container">
    <h2>{{ listPictosData.title }}</h2>
    <div class="row">
      <div v-for="item in listPictosData.pictos" :key="item.alt" class="picto-list-item col-xs-12 col-md-4">
        <img :src="item.picto" :alt="item.alt" />
        <p>{{ item.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listPictosData: {
      type: Array,
      default:() => [],
    },
  },

  
};
</script>

<style lang="scss">
.list-pictos-container{
  padding-top: 50px;
  margin-bottom: 60px;
  align-items: baseline;
  @media screen and (min-width: $breakpoint-sm-max) {
    padding: 50px 120px 50px 120px;
    border-bottom: 1px solid #cecece;
    margin-bottom: 0;
  }
  @media screen and (min-width: $breakpoint-lg-min) {
    padding: 50px 270px 50px 237px;
  }
  h2{
    text-align:center;
    color: $secondary;
    margin-bottom: 40px;
  }
}
.picto-list-item{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 67px;
  img{
    max-height: 153px;
  }
  p{
    text-align: center;
    font-weight: bold;
    font-family: 'PoppinsBold';
  }
}
</style>
