<template>
  <BaseSSRHeader :links="links">
    <template #headerBtnsRight>
      <!-- Connexion button -->
      <QBtn no-caps dense class="bg-white text-secondary q-px-md" type="a" :href="trackedOriginCandidatUrl" :label="$t('JOBS.LABELS.@LOGIN')" />
    </template>

    <template #drawerList>
      <!-- Connexion button -->
      <QItem class="justify-center">
        <QBtn no-caps dense class="bg-white text-secondary q-px-md" type="a" :href="CANDIDATES_URL" :label="$t('JOBS.LABELS.@LOGIN')" />
      </QItem>
    </template>

    <template #drawerFooter>
      <AppBanner candidate />
    </template>
  </BaseSSRHeader>
</template>

<script>
  import { JOBS_ROUTES } from "@jobs/config";
  import { BLOG_URL, CANDIDATES_URL, FAQ_CANDIDATES_URL, SHOWCASE_RECRUITMENT_URL } from "@core/constants";
  import AppBanner from "@core/components/media/AppBanner.component.vue";
  import BaseSSRHeader from "@core/components/layouts/Base.SSR.header.vue";

  const UTM_DEFAULT = "jobs_cta_connexion";

  export default {
    name: "JobsHeader",
    components: {
      AppBanner,
      BaseSSRHeader,
    },
    computed: {
      trackedOriginCandidatUrl() {
        const utmQuery = this.$route.query.utm_campaign;
        return utmQuery ? `${this.CANDIDATES_URL}?utm_campaign=${utmQuery}` : `${this.CANDIDATES_URL}?utm_campaign=${UTM_DEFAULT}`;
      },
    },
    created() {
      this.initLinks();
    },
    methods: {
      initLinks() {
        this.CANDIDATES_URL = CANDIDATES_URL;
        this.links = [
          {
            to: { name: JOBS_ROUTES.public.search.name, query: this.$route.query },
            icon: "work",
            label: "JOBS.LABELS.@LINK_SEARCH",
          },
          {
            href: SHOWCASE_RECRUITMENT_URL,
            icon: "fas fa-handshake",
            label: "JOBS.LABELS.@LINK_LANDING_PAGES",
            target: "_blank",
          },
          {
            href: BLOG_URL,
            label: "JOBS.LABELS.@LINK_BLOG",
            icon: "menu_book",
            target: "_blank",
          },
          {
            href: FAQ_CANDIDATES_URL,
            label: "JOBS.LABELS.@LINK_QUESTION",
            icon: "icon-f-help-circle",
            target: "_blank",
          },
        ];
      },
    },
  };
</script>
