<template>
  <div class="jobs-no-results-component">
    <section>
      <QBanner v-if="!typeformLink" class="bg-primary-medium text-white">
        <template #avatar>
          <QIcon name="error_outline" color="white" class="title-icon" />
        </template>
        <span>
          {{ $t("JOBS.SEARCH.@NO_RESULTS") }}
        </span>
      </QBanner>
    </section>
    <section v-if="typeformLink">
      <h2>{{ $t("JOBS.SEARCH.@NO_RESULTS_REGION", { region: label }) }}</h2>
      <QBanner rounded class="bg-grey-3 q-mt-lg">
        <div class="no-result-title">
          <QIcon name="icon-extra-alert" size="xl" />
          <h2>{{ $t("JOBS.SEARCH.@NO_RESULTS_BANNER_TITLE") }}</h2>
        </div>
        <p class="text-bold text-center">
          {{ $t("JOBS.SEARCH.@NO_RESULTS_ALERT") }}
        </p>
        <template #action>
          <div class="no-result-action">
            <QBtn color="secondary" :label="$t('JOBS.LABELS.@ALERT')" @click="openTypeformPopup" />
          </div>
        </template>
      </QBanner>
    </section>
  </div>
</template>

<script>
  import { EventManager } from "@core/managers/event.manager";
  import { JOBS_EVENTS } from "@jobs/config";

  export default {
    name: "JobOfferSearchNoResults",
    props: {
      isRegion: {
        type: Boolean,
        default: false,
      },
      typeformLink: {
        type: String,
        default: "",
      },
      label: {
        type: String,
        default: "",
      },
    },
    methods: {
      openTypeformPopup() {
        const typeformUrl = this.typeformLink;
        EventManager.emit(JOBS_EVENTS.OPEN_TYPEFORM, typeformUrl);
      },
    },
  };
</script>

<style lang="scss">
  .jobs-no-results-component {
    .no-result-title {
      display: flex;
      align-items: center;
      > h2 {
        margin-left: 20px;
      }
    }
    .no-result-action {
      padding: 20px;
      margin: 0 auto;
    }
  }
</style>
