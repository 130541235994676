<template>
  <div class="extracadabra-base-header">
    <QHeader elevated>
      <QToolbar>
        <a :href="homeLink" class="extracadabra-base-header-logo">
          <img :src="require('@core/assets/img/logo_extracadabra_full.svg')" :draggable="false" alt="logo extracadabra" class="q-py-xs" />
        </a>

        <!-- MOBILE ONLY -->
        <QSpace class="mobile-screens" />

        <!-- MOBILE ONLY -->
        <section v-if="!hideBurgerMenu" class="mobile-screens">
          <QBtn flat :icon="drawerOpened ? 'icon-f-arrow-left' : 'icon-f-menu'" no-caps @click="toggleDrawer" />
        </section>

        <!-- DESKTOP ONLY -->
        <nav role="navigation" :aria-label="$t('JOBS.LABELS.@NAV_ARIA_LABEL')" class="desktop-screens">
          <QBtn
            v-for="(link, index) in links"
            :key="`desktop-${index}`"
            :type="link.href ? 'a' : 'button'"
            :href="link.href"
            :to="link.to"
            :target="link.target"
            flat
            :icon="link.icon ? link.icon : null"
            :label="$t(link.label)"
            no-caps
          />

          <slot name="headerBtnsLeft" />
        </nav>

        <!-- DESKTOP ONLY -->
        <QSpace class="desktop-screens" />

        <!-- DESKTOP ONLY -->
        <section class="desktop-screens">
          <slot name="headerBtnsRight" />
        </section>

        <!-- MOBILE ONLY -->
        <section class="mobile-screens">
          <slot name="headerMobileBtnsRight" />
        </section>
      </QToolbar>
    </QHeader>

    <QDrawer v-model="drawerOpened" side="right" class="extracadabra-base-header-drawer" overlay behavior="mobile" no-swipe-open>
      <QList padding class="extracadabra-base-header-drawer-list">
        <QItem v-if="drawerSubtitle" class="extracadabra-base-header-drawer-subtitle">
          <QItemSection>
            {{ $t(drawerSubtitle) }}
          </QItemSection>
        </QItem>

        <QItem v-for="(link, index) in links" :key="`mobile-${index}`">
          <QBtn
            :key="index"
            :type="link.href ? 'a' : 'button'"
            :href="link.href"
            :to="link.to"
            :target="link.target"
            flat
            :icon="link.icon ? link.icon : null"
            :label="$t(link.label)"
            no-caps
          />
        </QItem>

        <slot name="drawerList" />
      </QList>

      <footer class="extracadabra-base-header-drawer-footer absolute-bottom">
        <slot name="drawerFooter" />
      </footer>
    </QDrawer>
  </div>
</template>

<script>
  export default {
    props: {
      homeLink: {
        type: String,
        default: "/",
      },
      drawerSubtitle: {
        type: String,
        default: "",
      },
      hideBurgerMenu: {
        type: Boolean,
        default: false,
      },
      links: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        drawerOpened: false,
      };
    },
    computed: {
      screenBreakpoint() {
        return this.$q.screen.gt.sm;
      },
    },
    watch: {
      screenBreakpoint(screenBreaking, oldValue) {
        if (screenBreaking === true) {
          this.drawerOpened = false;
        }
      },
    },
    methods: {
      toggleDrawer() {
        this.drawerOpened = !this.drawerOpened;
      },
    },
  };
</script>
