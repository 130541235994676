const hero = {
  title: '<span class="secondary">Travaille</span> au rythme<br /><span class="secondary">de tes envies en vente !</span>',
  subtitle:
    "<strong>Vendeur en boucherie, charcuterie ou fromagerie !</strong> Découvre les meilleures offres en grande surface ou épicerie haut de gamme.",
  bg_mob: "https://staticadabra.s3.amazonaws.com/jobs/marketing/landing/fond-boucherie-mobile.png",
  bg_desk: "https://staticadabra.s3.amazonaws.com/jobs/marketing/landing/fond-boucherie-desktop.png",
  cta: [
    {
      color: "secondary",
      text: "Rejoins-nous",
      url: "https://extracadabra-paris.typeform.com/to/rGBGZdQx?utm_campaign=xxxxx#nom=xxxxx&prenom=xxxxx&email=xxxxx&id_bo=xxxxx&telephone=xxxxx",
      slug: "demo",
    },
  ],
};

const app = {
  title: "Téléchargez l’app extracadabra",
  image_mobile: "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/hidf-lyon/phone_app_mobile.png",
  image_desktop: "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/hidf-lyon/phone_app_desktop.png",
  apps: [
    {
      logo: "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/playstore_logo.png",
      url: "https://play.google.com/store/apps/details?id=com.extracadabra.android.extra",
      title: "Play store",
      alt: "Play store logo",
      slug: "play_store",
    },
    {
      logo: "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/applestore_logo.png",
      url: "https://apps.apple.com/fr/app/extracadabra-etablissement/id1056999788",
      title: "Apple store",
      alt: "Apple store logo",
      slug: "apple_store",
    },
  ],
};

const verbatim = {
  title: "Nos extras en parlent mieux que nous !",
  verbatim: [
    {
      position: "left",
      text: "<figure><blockquote><p>“Travailler comme boucher avec Extracadabra a été une révélation pour moi. Je peux enfin exercer mon métier avec passion et flexibilité. Les missions sont variées et intéressantes !”</p></blockquote></figure>",
      author: "<strong>Victor, 28 ans, Boucher</strong>",
      avatar: "https://staticadabra.s3.amazonaws.com/jobs/marketing/landing/victor.png",
      image_alt: "Avatar de Victor",
    },
    {
      position: "right",
      text: "<figure><blockquote><p>”Cela fait quelques mois que j’utilise l’app, je cherchais à être plus indépendante. Extracadabra me donne beaucoup de liberté, je choisis où et quand je veux travailler. J’accepte les missions que je veux, ça me permet d’être flexible. Il y a aussi un très bon suivi après ta mission”</p></blockquote></figure>",
      author: "<strong>Émilie, 35 ans, Charcutière</strong>",
      avatar: "https://staticadabra.s3.amazonaws.com/jobs/marketing/landing/emilie.png",
      image_alt: "Avatar de Emilie",
    },
  ],
};

const whyExtracadabra = {
  title: "Trouver une mission n’a jamais été aussi simple !",
  subtitle: "<p>De la création de compte au paiement, Extracadabra t’accompagne tout au long de ta mission.</p>",
  background:
    "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/illustration_bg_desktop_why_extra.png",
  list_step: [
    {
      text: "Crée ton profil en 2 minutes. Complète tes expériences, tes compétences, tes critères de recherche. Définit comment tu veux travailler !",
    },
    {
      text: "Reçois des propositions. Tous les jours, nous t’envoyons des dizaines d’offres ciblées adaptées à ton profil et tes envies.",
    },
    {
      text: "Travaille quand tu veux. Postule ou refuse une offre en fonction de tes critères et de tes disponibilités. ",
    },
  ],
};

const reinforcement = {
  title: "UNE SOLUTION SIMPLE ET RAPIDE POUR RENFORCER VOS ÉQUIPES EN RESTAURATION COLLECTIVE",
  subtitle:
    "<p>Travailler avec Extracadabra, c’est travailler sereinement ! De nombreuses fonctionnalités et avantages sont disponibles en passant par nos services :</p>",
  background:
    "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/illustration_bg_desktop_reinforcement.png",
  list_step: [
    {
      title: "UN PLANNING",
      text: "Vous disposez d’une vue depuis votre backoffice sur l’ensemble du planning des extras en mission",
    },
    {
      title: "LIBRE DE VOTRE CHOIX",
      text: "Vous avez le choix de l'extra avec une visibilité de son CV, de ses expériences et des avis. Il est par ailleurs simple de travailler par la suite avec vos candidats favoris",
    },
    {
      title: "ACCÉS MULTICOMPTE",
      text: "Un accès multicompte pour gérer plusieurs établissements à la fois",
    },
    {
      title: "UN TARIF GARANTI",
      text: "Il n’y a pas de coefficient de tension appliqué",
    },
    {
      title: "ASSURANCE AXA",
      text: "Nos Extras sont couverts par AXA et bénéficient d’une prévoyance",
    },
    {
      title: "DES CANDIDATS QUALIFIÉS",
      text: "Nous effectuons une pré-qualification pour nous assurer de vous proposer des talents compétents sur des postes à forte valeur ajoutée.",
    },
  ],
};

const interimOffer = {
  title: "Trouver une mission n’a jamais été aussi simple !",
  // subtitle: "<p>Extracadabra propose désormais une offre dédiée à l'intérim. Retrouvez vos candidats préférés, toujours aussi qualifiés, mais sous contrat d'intérim. En plus de nos avantages habituels (système de matching, contact opérationnel, sourcing personnalisé...), bénéficiez :</p>",
  image_desktop:
    "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/illustration_interim.png",
  image_mobile:
    "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/illustration_bg_mobile_why_extra.png",
  isLarge: true,
  list_step: [
    {
      text: "Crée ton profil en 2 minutes. Complète tes expériences, tes compétences, tes critères de recherche. Définit comment tu veux travailler !",
    },
    {
      text: "Reçois des propositions. Tous les jours, nous t’envoyons des dizaines d’offres ciblées adaptées à ton profil et tes envies. ",
    },
    {
      text: "Travaille quand tu veux. Postule ou refuse une offre en fonction de tes critères et de tes disponibilités.",
    },
  ],
};

const listPictos = {
  title: "Rejoins notre communauté de talents",
  pictos: [
    {
      picto: "https://staticadabra.s3.amazonaws.com/recrutement/marketing/landing-page/hidf-lyon/picto-conseiller.png",
      alt: "Picto conseiller",
      text: "Une équipe dédiée pour t’accompagner avant, pendant et après ta mission.",
    },
    {
      picto: "https://staticadabra.s3.amazonaws.com/recrutement/marketing/landing-page/hidf-lyon/picto-axa.png",
      alt: "Picto axa",
      text: "Une assurance Axa : En tant qu’auto-entrepreneur, tu es assuré gratuitement par Extracadabra auprès d’AXA.",
    },
    {
      picto: "https://staticadabra.s3.amazonaws.com/recrutement/marketing/landing-page/hidf-lyon/picto-facturation.png",
      alt: "Picto facturation",
      text: "Rémunération : reçois tes paiement de facture tous les 15 jours et garde l'esprit tranquille !",
    },
  ],
};

const faq = [
  {
    question: "Comment augmenter mes chances d’être pris à une mission ?",
    answer:
      "<p>Pour avoir plus de chances d'être sélectionné, complète au maximum ton profil pour le mettre en valeur auprès des recruteurs. Renseigne notamment toutes tes informations personnelles, ajoute une photo de profil sérieuse ainsi que tes meilleures expériences. Tu peux également demander une recommandation à l’un de tes anciens managers.</p>",
  },
  {
    question: "Qu'est-ce que je dois faire avant de postuler à une mission ?",
    answer:
      "<p>*Pense à vérifier que tu remplis toutes les conditions demandées par le recruteur et que le job te convient :<br /><br /><strong>Le poste demandé</strong><br />Tu dois déjà avoir travaillé à ce poste ou à un poste similaire auparavant.<br /><br /><strong>Le planning</strong><br />Il doit te convenir et tu dois être disponible les jours renseignés.<br /><br /><strong>La localisation</strong><br />Vérifie que la localisation te convient, que ce n'est pas trop loin de ton domicile pour ne pas avoir de mauvaises surprises. Tu retrouveras sur l'annonce le code postal de l'établissement ou l'adresse exacte.<br /><br /><strong>La tenue vestimentaire et le matériel</strong><br />Pense à vérifier que tu possèdes la tenue vestimentaire et le matériel demandé. Si tu as besoin d'équipement, nous avons plusieurs partenaires qui te permettront d’acheter ton matériel professionnel sans te ruiner, <a href=\"https://www.extracadabra.com/blog/articles/nos-partenaires-a-votre-service\" target=\"_blank\">juste ici.</a><br />En vérifiant ces conditions avant de postuler, tu auras plus de chances d'être recontacté par le recruteur pour un entretien ou un essai.</p>",
  },
  {
    question: "Quel est le salaire et quels sont les avantages du vendeur spécialisé ?",
    answer:
      "<p>En fonction de l'expérience et de la position, les salaires peuvent être compétitifs. De plus, certains employeurs proposent des avantages supplémentaires tels que des remises sur les produits ou des programmes d'assurance.<br /><br />Les salaires pour les vendeurs spécialisés en grande surface ou en épicerie haut de gamme peuvent varier considérablement en fonction de plusieurs facteurs, notamment l'expérience, la localisation géographique, la taille de l'entreprise et le niveau de responsabilité. Voici une estimation générale :</p><ul><li>Débutant sans expérience préalable : Le salaire peut être compris entre le salaire minimum légal et un peu plus élevé, en fonction de la région et de la politique de rémunération de l'entreprise.</li><li>Avec quelques années d'expérience : Les vendeurs spécialisés qui ont accumulé de l'expérience peuvent s'attendre à un salaire plus élevé que les débutants. Ce salaire peut être de 20 à 30 % supérieur au salaire minimum.</li></ul>",
  },
  {
    question: "Pourquoi travailler sur Extracadabra ?",
    answer:
      "<p>En travaillant avec nous, tu as la possibilité de travailler où tu veux, quand tu veux. Tu pourras recevoir des jobs en temps réel, travailler dans les lieux les plus tendances partout en France. Nous souhaitons te proposer des salaires attractifs et l’accompagnement d’une équipe toujours là pour toi en cas de besoin !</p>",
  },
];

export class VendeurSpeService {
  static getHeroBanner() {
    return hero;
  }

  static getAppBanner() {
    return app;
  }

  static getVerbatim() {
    return verbatim;
  }

  static getInterimOffer() {
    return interimOffer;
  }

  static getWhyExtracadabra() {
    return whyExtracadabra;
  }

  static getReinforcement() {
    return reinforcement;
  }

  static getListPictos() {
    return listPictos;
  }

  static getFaq() {
    return faq;
  }
}
