<template>
  <div
    class="list-step-number"
    :class="[backgroundColor, position]"
    :style="{ backgroundImage: isLargeScreen ? 'url(' + componentData.background + ')' : 'none' }"
  >
    <h2>{{ componentData.title }}</h2>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-html="componentData.subtitle" />
    <ul class="row">
      <li v-for="item in componentData.list_step" :key="item.text" class="col-xs-12 col-md-6">
        <span class="list-step-number-counter" :class="item.title ? 'with-title' : 'without-title'">
          <span>
            <span v-if="item.title" class="title">{{ item.title }}</span>
          </span>
        </span>
        <p>{{ item.text }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      listData: {
        type: Object,
        default: () => ({}),
      },
      backgroundColor: {
        type: String,
        default: "grey",
      },
      position: {
        type: String,
        default: "left",
      },
    },
    data() {
      return {
        componentData: [],
        isLargeScreen: false,
      };
    },

    created() {
      this.componentData = this.listData;
    },
    mounted() {
      this.updateIsLargeScreen();
      window.addEventListener("resize", this.updateIsLargeScreen);
    },
    beforeUnmount() {
      window.removeEventListener("resize", this.updateIsLargeScreen);
    },
    methods: {
      updateIsLargeScreen() {
        this.isLargeScreen = window.innerWidth >= 1024;
      },
    },
  };
</script>

<style lang="scss">
  .list-step-number {
    padding: 44px 14px 0 14px;
    text-align: center;
    &.right {
      @media screen and (min-width: $breakpoint-sm-max) {
        text-align: left;
        background-repeat: no-repeat;
        padding: 44px 120px 44px 335px;
        background-size: contain;
        background-position-x: -254px;
      }
      @media screen and (min-width: $breakpoint-lg-min) {
        background-size: contain;
        background-position-x: 0;
        padding: 44px 120px 44px 704px;
      }
      @media (min-width: $breakpoint-xl-min) {
        padding: 40px 125px 0 727px;
      }
    }
    &.left {
      @media screen and (min-width: $breakpoint-sm-max) {
        text-align: left;
        background-repeat: no-repeat;
        padding: 44px 335px 44px 120px;
        background-size: contain;
        background-position: right;
        background-position-x: 131%;
      }
      @media screen and (min-width: $breakpoint-lg-min) {
        background-size: contain;
        padding: 40px 715px 0 237px;
        background-position-x: 100%;
      }
    }
    h2 {
      font-family: "PoppinsBold";
      font-size: 24px;
      text-transform: uppercase;
      margin: 0 0 44px 0;
    }
    ul {
      counter-reset: my-counter;
      list-style-type: none;
      padding: 0;
      margin-bottom: 0;
      margin-top: 30px;
      @media screen and (min-width: $breakpoint-sm-max) {
        justify-content: space-between;
      }
      li {
        counter-increment: my-counter;
        @media screen and (min-width: $breakpoint-sm-max) {
          width: 48% !important;
        }
        .list-step-number-counter {
          display: flex;
          align-items: center;
          margin: 0;
          &:before {
            content: "";
            height: 1px;
            width: 100%;
            display: block;
            background-color: #fff;
            margin-right: 10px;
            @media screen and (min-width: $breakpoint-sm-max) {
              display: none;
            }
          }
          &:after {
            content: "";
            height: 1px;
            width: 100%;
            display: block;
            background-color: #fff;
            margin-left: 10px;
          }
          & > span {
            color: transparent;
            display: flex;
            &:before {
              content: counter(my-counter);
              font-weight: bold;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #fff;
              height: 35px;
              width: 35px;
              border-radius: 56px;
              font-size: 22px;
              font-family: "PoppinsBold";
            }
          }
          &.with-title {
            border-bottom: 1px solid #fff;
            padding-bottom: 10px;
            .title {
              color: #fff;
              margin-left: 15px;
              font-family: "PoppinsBold";
              font-size: 20px;
            }
            &:after,
            &:before {
              display: none;
            }
            & > span {
              display: flex;
              align-items: center;
            }
          }
        }
        p {
          padding: 30px 0;
          font-weight: bold;
          @media screen and (min-width: $breakpoint-sm-max) {
            padding: 18px 15px 18px 0;
            margin-bottom: 40px;
          }
        }
      }
    }
    &.blue {
      background-color: $primary;
      color: #fff;
      .list-step-number-counter {
        span {
          color: $primary;
        }
      }
    }
    &.pink {
      background-color: $secondary;
      color: #fff;
      .list-step-number-counter {
        span {
          color: $secondary;
        }
      }
    }
  }
</style>
