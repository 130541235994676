<template>
  <section class="job-list-component q-gutter-md q-pt-md">
    <template v-if="isSmallScreen">
      <OfferCardMobile v-for="offer in jobOffersList" :key="offer.getId()" :apply-disabled="isApplyDisabled" :offer="offer" />
      <!-- In odd lists, add an empty card to fix alignment -->
      <div v-if="jobOffersList.length % 2" class="jobs-offer-card-mobile" />
    </template>
    <template v-else>
      <OfferCardDesktop v-for="offer in jobOffersList" :key="offer.getId()" :apply-disabled="isApplyDisabled" :offer="offer" />
      <!-- In odd lists, add an empty card to fix alignment -->
      <div v-if="jobOffersList.length % 2" class="jobs-offer-card-desktop" />
    </template>
  </section>
</template>

<script>
  import OfferCardDesktop from "@core/components/candidates/jobs/card/Offer.card.desktop.vue";
  import OfferCardMobile from "@core/components/candidates/jobs/card/Offer.card.mobile.vue";

  export default {
    name: "JobOffersList",
    components: {
      OfferCardMobile,
      OfferCardDesktop,
    },
    props: {
      jobOffersList: {
        type: Array,
        default: () => [],
      },
      isApplyDisabledAe: {
        type: Boolean,
        default: false,
      },
      isApplyDisabledMer: {
        type: Boolean,
        default: false,
      },
      isApplyDisabledPermanent: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        modalOpened: false,
        selectedOffer: null,
        modalType: "default",
        isAE: false,
      };
    },
    computed: {
      isApplyDisabled() {
        return this.isApplyDisabledPermanent || this.isApplyDisabledMer || this.isApplyDisabledAe;
      },
      isSmallScreen() {
        return this.$q.screen.lt.md;
      },
    },
  };
</script>

<style lang="scss">
  .job-list-component {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media screen and (min-width: $breakpoint-md-max) {
      // take up more space for offer cards on big screens
      margin-left: -100px;
      margin-right: -100px;
    }
  }
</style>
