<template>
  <section class="row justify-center media-stores" :class="fullSize ? 'media-full-size' : ''">
    <!-- APPLE APP STORE -->
    <figure>
      <a
        v-show="shouldDisplayAppStoreCandidate"
        :href="EXTRA_APPLE_STORE_LINK"
        target="__blank"
        aria-label="Apple Store Extra"
        @click="trackStoreClick('ios')"
      >
        <img :src="require('@core/assets/img/stores/appstore.png')" alt="Apple Store Extra" />
      </a>
      <a v-show="shouldDisplayAppStoreEtab" :href="ETAB_APPLE_STORE_LINK" target="__blank" aria-label="Apple Store Etab">
        <img :src="require('@core/assets/img/stores/appstore.png')" alt="Apple Store Etab" />
      </a>
    </figure>

    <!-- GOOGLE PLAY STORE -->
    <figure>
      <a
        v-show="shouldDisplayPlayStoreCandidate"
        :href="EXTRA_PLAY_STORE_LINK"
        target="__blank"
        aria-label="Play Store Extra"
        @click="trackStoreClick('android')"
      >
        <img :src="require('@core/assets/img/stores/playstore.png')" alt="Play Store Extra" />
      </a>
      <a v-show="shouldDisplayPlayStoreEtab" :href="ETAB_PLAY_STORE_LINK" target="__blank" aria-label="Play Store Etab">
        <img :src="require('@core/assets/img/stores/playstore.png')" alt="Play Store Etab" />
      </a>
    </figure>
  </section>
</template>

<script>
  import { DEVICE_OS, ETAB_APP_ID_ANDROID, ETAB_APP_ID_IOS, EXTRA_APP_ID_ANDROID, EXTRA_APP_ID_IOS, STORES } from "@core/constants";
  import { StoresService } from "@core/plugins/mobile";
  import { FirebaseManager } from "@core/plugins/firebase/firebase.manager";
  import { JavascriptHelpers } from "@core/helpers";

  export default {
    name: "Social",
    props: {
      candidate: {
        type: Boolean,
        default: false,
      },
      etab: {
        type: Boolean,
        default: false,
      },
      fullSize: {
        type: Boolean,
        default: false,
      },
      smartStore: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      shouldDisplayAppStoreEtab() {
        return this.etab && this.shouldDisplayOnSmartMobile(DEVICE_OS.IOS);
      },
      shouldDisplayPlayStoreEtab() {
        return this.etab && this.shouldDisplayOnSmartMobile(DEVICE_OS.ANDROID);
      },
      shouldDisplayAppStoreCandidate() {
        return this.candidate && this.shouldDisplayOnSmartMobile(DEVICE_OS.IOS);
      },
      shouldDisplayPlayStoreCandidate() {
        return this.candidate && this.shouldDisplayOnSmartMobile(DEVICE_OS.ANDROID);
      },
    },
    created() {
      this.initLinks();
    },
    methods: {
      initLinks() {
        this.EXTRA_APPLE_STORE_LINK = StoresService.getAppleStoreLink(EXTRA_APP_ID_IOS);
        this.EXTRA_PLAY_STORE_LINK = StoresService.getAndroidStoreLink(EXTRA_APP_ID_ANDROID);

        this.ETAB_APPLE_STORE_LINK = StoresService.getAppleStoreLink(ETAB_APP_ID_IOS);
        this.ETAB_PLAY_STORE_LINK = StoresService.getAndroidStoreLink(ETAB_APP_ID_ANDROID);

        this.STORES = STORES;
      },
      shouldDisplayOnSmartMobile(platform) {
        // if smartStore is not defined, display anyway
        if (!this.smartStore) {
          return true;
        }
        // otherwise, check if we are on mobile
        const operatingSystem = JavascriptHelpers.getMobileOperatingSystem();
        if (operatingSystem === DEVICE_OS.IOS || operatingSystem === DEVICE_OS.ANDROID) {
          // on mobile we return true only for the selected platform
          return operatingSystem === platform;
        }
        if (operatingSystem === DEVICE_OS.SERVER) {
          // for SSR we don't display store (it will update after initial loading)
          return false;
        }
        // we display all the time for desktop
        return true;
      },
      trackStoreClick(platform) {
        FirebaseManager.logEvent({
          name: `home_click_dowload_app_${platform}`,
        });
      },
    },
  };
</script>

<style lang="scss">
  .media-stores {
    min-height: 60px;
    a {
      text-decoration: none;
      display: inline-block;
    }
    img {
      width: 150px;

      @media screen and (max-width: $breakpoint-sm-max) {
        width: 120px;
      }
    }

    &.media-full-size {
      min-height: 100px;
      img {
        width: 250px !important;
      }
    }
  }
</style>
