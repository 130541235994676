<template>
  <div
    class="jobs-offer-card-mobile cursor-pointer"
    :class="['jobs-offer-card-mobile-theme-' + offer.getContractTypeColor()]"
    @click="getOfferLink()"
  >
    <header class="q-mb-sm row justify-between">
      <ExtraTag :label="$t(offer.getContractTypeDisplay())" :background="offer.getContractTypeColor()" />
    </header>

    <QCard :id="offer.getId()">
      <span class="q-focus-helper" />
      <div class="jobs-offer-card-mobile-header">
        <img :src="offer.getPicture()" />
        <div class="jobs-offer-card-mobile-salary">
          <!-- Permanent Salary -->
          <template v-if="offer.isPermanent()">
            <section>
              <div class="text-h3">
                <p>{{ salaryDisplay }}</p>
                <p v-if="periodicity" class="text-subtitle2 white">{{ periodicity }}</p>
              </div>
            </section>
          </template>
          <!-- AE Salary -->
          <template v-if="offer.isAE()">
            <section>
              <div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span class="text-h3">{{ salaryDisplay }}</span>
                <p class="text-subtitle2 white">
                  {{ $t("CORE.COMPONENTS.OFFERS.@SALARY_AE_TOTAL") }}
                  <br />
                  {{ offer.getSalary().getForecastDisplay() }}
                </p>
              </div>
            </section>
          </template>
          <!-- Interim Salary -->
          <template v-if="offer.isInterim()">
            <section>
              <div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span class="text-h3">{{ salaryDisplay }}</span>
                <p class="text-subtitle2 white">
                  {{ $t("CORE.COMPONENTS.OFFERS.@SALARY_INTERIM_TOTAL") }}
                  <br />
                  {{ offer.getSalary().getForecastDisplay() }}
                </p>
              </div>
            </section>
          </template>
        </div>
      </div>
      <div class="jobs-offer-card-body column flex">
        <QCardSection class="jobs-offer-card-mobile-infos column justify-around">
          <div class="q-pb-none">
            <div class="flex column">
              <span class="q-ma-none text-bold text-h4">
                {{ offer.getQualification() }}
              </span>
              <span v-if="offer.getEtab().getName()" class="q-pt-sm text-bold text-h4">
                {{ $filters.whitespacedPunctuation(offer.getEtab().getName()) }}
              </span>
            </div>
            <div class="q-py-sm">
              <QItem dense>
                <QItemSection class="jobs-offer-card-mobile-icon" avatar>
                  <QIcon size="20px" name="fad fa-store" />
                </QItemSection>
                <QItemLabel>
                  {{ offer.getEtab().getCategory() }}
                </QItemLabel>
              </QItem>
              <QItem dense>
                <QItemSection class="jobs-offer-card-mobile-icon" avatar>
                  <QIcon size="20px" name="fad fa-map-marker-alt" />
                </QItemSection>
                <QItemLabel>{{ offer.getPostalCode() }} {{ offer.getCity() }}</QItemLabel>
              </QItem>
            </div>
          </div>
          <div>
            <section class="text-center q-gutter-sm q-pr-sm q-pb-xs">
              <QBtn
                class="btn-large font-095"
                dense
                :label="$t('CORE.COMPONENTS.OFFERS.@GO_TO_OFFER')"
                color="secondary"
                no-caps
                @click="getOfferLink()"
              />
            </section>
          </div>
        </QCardSection>
        <QCardSection class="q-py-sm jobs-offer-card-mobile-footer">
          <QItem dense class="flex row justify-between">
            <section class="jobs-offer-card-mobile-footer-section">
              <QItemLabel class="text-weight-bold">
                {{ $t("CORE.COMPONENTS.OFFERS.@START_DATE") }}
              </QItemLabel>
              <QItemLabel class="flex items-center justify-start">
                <p>
                  <QIcon size="16px" name="far fa-calendar" />
                  {{ offer.getStartDateDisplay() }}
                </p>
              </QItemLabel>
              <QItemLabel v-if="offer.isTemporary()">
                <p>{{ offer.getTypicalSchedule().getSchedule() }}</p>
              </QItemLabel>
            </section>
            <section class="jobs-offer-card-mobile-footer-section">
              <!-- AE Duration -->
              <template v-if="offer.isTemporary()">
                <QItemLabel class="text-right text-weight-bold">
                  {{ $t("CORE.COMPONENTS.OFFERS.@DURATION") }}
                </QItemLabel>
                <QItemLabel v-if="offer.getDuration() !== undefined" class="flex items-center justify-end text-right">
                  <p>
                    {{ offer.getDuration() }}
                    <QIcon size="16px" name="far fa-clock" />
                  </p>
                </QItemLabel>
              </template>
              <!-- Permanent Duration -->
              <template v-if="offer.isPermanent()">
                <QItemLabel v-if="offer.getDuration() !== undefined || nbDays" class="text-right text-weight-bold">
                  {{ $t("CORE.COMPONENTS.OFFERS.@DURATION") }}
                </QItemLabel>
                <QItemLabel v-if="offer.getDuration() !== undefined" class="text-right">
                  <p>{{ offer.getDuration() }}</p>
                </QItemLabel>
                <QItemLabel v-if="offer.isSeason()" class="text-right">
                  <p>{{ nbDays }}</p>
                </QItemLabel>
              </template>
            </section>
          </QItem>
        </QCardSection>
      </div>
    </QCard>
    <div class="row justify-between">
      <span>&nbsp;</span>
      <span class="font-080 text-grey-5 text-italic q-pa-sm">
        {{ $t("CORE.COMPONENTS.OFFERS.@PUBLICATION_DATE", { date: offer.getPublicationDateFromNow() }) }}
      </span>
    </div>
  </div>
</template>

<script>
  import { JobOfferDetailModel } from "@core/models";
  import { CANDIDATES_OFFER_STATUS } from "@core/constants";
  import { JOBS_ROUTES } from "@jobs/config";

  export default {
    props: {
      offer: {
        type: Object,
        default: () => new JobOfferDetailModel(),
      },
    },
    computed: {
      showIsFluctuating() {
        return this.offer.getSalary().isFluctuating() && !this.offer.getSalary().hasRange();
      },
      periodicity() {
        const { offer } = this;
        const periodicity = offer.getSalary().getPeriodicity();
        return !this.showIsFluctuating ? periodicity?.toLowerCase() : null;
      },
      salaryDisplay() {
        const { offer } = this;
        const salary = offer.getSalary().getAmountDisplay();
        if (offer.isTemporary()) {
          return this.$t("CORE.COMPONENTS.JOB_OFFER.@SALARY_TEMPORARY", { salary });
        }
        return this.showIsFluctuating
          ? this.$t("CORE.COMPONENTS.JOB_OFFER.@FLUCTUATING")
          : this.$t("CORE.COMPONENTS.JOB_OFFER.@SALARY_PERMANENT_CARD", { salary });
      },
      isPermanentOffer() {
        return this.offer.isPermanent();
      },
      shouldDisplayTimeSheetButtons() {
        return (
          ((this.offer.isCanceledMissionStatus() || this.offer.isSelectedStatus()) && this.offer.getActivityReportUrl()) || this.offer.isReviewable()
        );
      },
      backgroundImg() {
        return { backgroundImage: `url(${this.offer.getPicture()})` };
      },
      nbDays() {
        const nbDaysDisplay = this.$tc("CORE.COMPONENTS.OFFERS.@NB_DAYS", this.offer.getNbDays());
        return this.offer.getNbDays() > 0 ? `${nbDaysDisplay}` : "";
      },
    },
    created() {
      this.CANDIDATES_OFFER_STATUS = CANDIDATES_OFFER_STATUS;
    },
    mounted() {
      this.preventMiddleClickScroll();
    },
    methods: {
      preventMiddleClickScroll() {
        const element = this.$el;
        element.onmousedown = (event) => {
          const isMiddleClick = event.button === 1;
          if (isMiddleClick) {
            return false; // this will prevent the middle click scroll behavior
          }
          return true;
        };
      },
      getOfferLink() {
        const query = this.$route.query;
        const utmQueryParams = Object.keys(query).reduce((obj, key) => {
          if (key.includes("utm")) {
            obj[key] = query[key];
          }
          return obj;
        }, {});

        const encodedSlug = encodeURIComponent(this.offer.getUrl());
        this.$router
          .push({ name: JOBS_ROUTES.public.offer.detail.name, query: utmQueryParams, params: { slug: encodedSlug } })
          .catch((suppressedError) => {});
      },
    },
  };
</script>

<style lang="scss">
  .jobs-offer-card-mobile {
    width: 100%;
    max-width: 400px;

    .q-card {
      border-radius: 0 15px 15px;
      .jobs-offer-card-body {
        overflow: auto;
        .jobs-offer-card-mobile-infos {
          flex: 1;
          min-height: 200px;
        }
      }
    }

    .jobs-offer-card-mobile-header {
      position: relative;
      > img {
        display: block;
        width: 100%;
        max-width: 100%;
      }
      > .jobs-offer-card-mobile-salary {
        position: absolute;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.85) 100%);
        top: 0;
        bottom: 0;
        right: 0;
        color: white;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding: 15px;
        text-align: right;
        h3 {
          max-width: 160px;
        }

        p {
          font-size: 0.8em;
          margin-bottom: 0;
        }
      }
    }

    .jobs-offer-card-mobile-icon {
      width: 30px !important;
      min-width: 30px !important;
    }

    .q-item {
      align-items: center;
      padding-left: 0;
      padding-right: 0;
    }

    .jobs-offer-card-mobile-footer {
      background-color: rgba(0, 0, 0, 0.15);
      height: auto;
      p {
        padding-left: 24px;
        margin-bottom: 0;
        position: relative;
        .q-icon {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      .text-right {
        p {
          padding-left: auto;
          margin-bottom: 0;
          .q-icon {
            position: relative;
            left: auto;
            top: auto;
            margin-right: 0;
            margin-left: 4px;
          }
        }
      }
    }
  }

  .jobs-offer-card-mobile-theme-secondary {
    .q-badge {
      background-color: $secondary;
    }
    .jobs-offer-card-mobile-footer {
      background-color: rgba($secondary, 0.15);
      height: 80px;
    }
    @media screen and (max-width: 370px) {
      .jobs-offer-card-mobile-footer {
        height: auto;
      }
      .jobs-offer-card-mobile-footer-section {
        min-height: 65px;
      }
    }
    &.jobs-offer-card-booking-favorite {
      .jobs-offer-card-mobile-footer {
        background: none;
        border-top: 1px solid rgba($secondary, 0.15);
      }
    }
  }
  .jobs-offer-card-mobile-theme-interim-green {
    .q-badge {
      background-color: $interim-green;
    }
    .jobs-offer-card-mobile-footer {
      background-color: rgba($interim-green, 0.15);
      height: 80px;
    }
    @media screen and (max-width: 370px) {
      .jobs-offer-card-mobile-footer {
        height: auto;
      }
    }
  }
  .jobs-offer-card-mobile-theme-season-orange {
    .q-badge {
      background-color: $season-orange;
    }
    .jobs-offer-card-mobile-footer {
      background-color: rgba($season-orange, 0.15);
      height: 80px;
    }
    @media screen and (max-width: 370px) {
      .jobs-offer-card-mobile-footer {
        height: auto;
      }
    }
  }

  .jobs-offer-card-mobile-theme-primary {
    .q-badge {
      background-color: $primary;
    }
    .jobs-offer-card-mobile-footer {
      background-color: rgba($primary, 0.15);
      height: 80px;
    }
  }
  .jobs-offer-card-mobile-footer-section {
    align-self: flex-start;
  }
  .offer-card-review-btns {
    width: 230px;
    margin: 0 auto;
  }
</style>
