<template>
  <main class="jobs-dynamic-block-component">
    <span class="text-h2">
      {{ title }}
    </span>
    <article class="jobs-dynamic-block-content">
      <section v-for="page in dynamicPages" :key="page.getId()" class="dynamic-block-link">
        <QCard v-if="page.getImg()" style="height: 150px">
          <img class="dynamic-image" :src="page.getImg()" :alt="page.getImgTitle()" />
          <div class="dynamic-link absolute-bottom" style="padding: 0">
            <h2 class="text-h3">
              <QBtn class="full-width" align="right" :to="getPageLink(page.getId())" :label="page.getLabel()" icon-right="icon-f-arrow-right" />
            </h2>
          </div>
        </QCard>
        <QBtn v-else color="accent" type="a" padding="50px" :to="getPageLink(page.getId())" :label="page.getLabel()" />
      </section>
    </article>
  </main>
</template>

<script>
import { JOBS_ROUTES } from "@jobs/config";

export default {
  name: "DynamicPagesBlock",
  props: {
    dynamicPages: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    getPageLink(slug) {
      const query = this.$route.query;

      const encodedSlug = encodeURIComponent(slug);
      const routeData = this.$router.resolve({ name: JOBS_ROUTES.public.dynamic.name, query, params: { slug: encodedSlug } });
      return routeData.href;
    },
  },
};
</script>

<style lang="scss" scoped>
.jobs-dynamic-block-component {
  .jobs-dynamic-block-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .dynamic-block-link {
      width: 100%;
      max-width: 370px;
      height: 150px;
      margin: 15px;
      .dynamic-image {
        height: 100%;
        object-fit: cover;
      }
      .dynamic-link {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.47);
        h3 {
          font-family: "Avenir";
          font-weight: 500;
          margin: 0;
        }
      }
    }
  }
}
</style>
