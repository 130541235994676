<template>
  <figure class="q-mx-none media-social">
    <a
      v-for="socialNetwork in SOCIAL_NETWORKS"
      :key="socialNetwork.iconName"
      :href="socialNetwork.link"
      target="__blank"
      :aria-label="$t(socialNetwork.iconAlt)"
    >
      <img
        v-if="socialNetwork.iconName !== 'twitter'"
        :src="require(`@core/assets/img/logos/logo-${socialNetwork.iconName}.svg`)"
        :alt="$t(socialNetwork.iconAlt)"
      />
    </a>
  </figure>
</template>

<script>
  import { SOCIAL_NETWORKS } from "@core/constants";

  export default {
    name: "Social",
    created() {
      this.SOCIAL_NETWORKS = SOCIAL_NETWORKS;
    },
  };
</script>

<style lang="scss" scoped>
  .media-social {
    display: flex;
    justify-content: left;
    @media screen and (max-width: $breakpoint-sm) {
      justify-content: center;
    }
    a {
      text-decoration: none;
    }
    img {
      margin: 0 5px;
    }
  }
</style>
