<template>
  <QLayout view="hHh LpR fff" class="extracadabra-layout frontpage-layout landing">
    <slot name="header" />
    <QPageContainer>
      <QPage>
        <div class="extracadabra-landing-layout v2">
          <div class="row extracadabra-landing-background">
            <div class="col-xs-12">
              <section class="extracadabra-landing-heading">
                <slot name="heading" />
              </section>
              <section class="extracadabra-landing-content">
                <slot name="content" />
              </section>
            </div>
          </div>
        </div>
      </QPage>
    </QPageContainer>
    <slot name="footer" />
  </QLayout>
</template>

<style lang="scss">
  .extracadabra-layout.landing {
    .base-footer-component {
      footer {
        position: relative;
      }
    }
    .extracadabra-base-header.landing {
      & > .q-header {
        background-color: #fff !important;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        @media screen and (min-width: $breakpoint-sm-max) {
          padding: 0 110px;
        }
        > * {
          justify-content: space-between;
          .extracadabra-landing-layout.v2 {
            font-family: "Poppins";
            font-size: 18px;
          }
        }
      }
    }
  }
</style>
