<template>
  <QLayout view="hHh LpR fff" class="extracadabra-layout">
    <JobsHeader />
    <QPageContainer>
      <QPage>
        <AppBanner can-close candidate />
        <article class="extracadabra-landing-layout">
          <slot name="backgroundImg" />
          <div class="extracadabra-landing-background">
            <article>
              <section v-if="!noHeading" class="extracadabra-landing-heading">
                <slot name="heading" />
              </section>
              <slot name="storesBanner" />
              <section class="extracadabra-landing-content row">
                <div class="col" />
                <div class="col-10">
                  <slot name="content" />
                </div>
                <div class="col" />
              </section>
            </article>
          </div>
        </article>
      </QPage>
    </QPageContainer>
    <JobsFooter />
  </QLayout>
</template>

<script>
import AppBanner from "@core/components/media/AppBanner.component.vue";
import JobsHeader from "@jobs/layouts/Jobs.header.vue";
import JobsFooter from "@jobs/layouts/Jobs.footer.vue";

export default {
  name: "PublicLayout",
  components: {
    JobsHeader,
    JobsFooter,
    AppBanner,
  },
  props: {
    noHeading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
