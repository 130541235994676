import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from "@core/constants";
import { Logger } from "@core/helpers/logger.helpers";
import { isWeb } from "../mobile";

export class FirebaseManager {
  static initialize() {
    Logger.info("FirebaseManager - Initializing Firebase");
    if (isWeb) {
      const firebaseConfig = {
        apiKey: FIREBASE_API_KEY,
        authDomain: FIREBASE_AUTH_DOMAIN,
        projectId: FIREBASE_PROJECT_ID,
        storageBucket: FIREBASE_STORAGE_BUCKET,
        messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
        appId: FIREBASE_APP_ID,
        measurementId: FIREBASE_MEASUREMENT_ID,
      };

      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
      analytics.app.automaticDataCollectionEnabled = true;
      Logger.info("FirebaseManager - Firebase initialized");
    }
  }

  static async logEvent({ name, params }) {
    return FirebaseAnalytics.logEvent({ name, params });
  }

  static async setUserProperties({ properties }) {
    return FirebaseAnalytics.setUserProperty({ properties });
  }

  static async setUserId({ userId }) {
    return FirebaseAnalytics.setUserId({ userId });
  }

  static async setCurrentScreen({ screenName }) {
    return FirebaseAnalytics.setCurrentScreen({ screenName });
  }
}
