<template>
  <div class="app-banner-landing">
    <div class="app-banner-landing-container row">
      <div class="col-xs-12 col-md-7 col-lg-6 illustration">
        <img class="desktop" :src="appData.image_desktop" alt="Illustration Application Extracadabra" />
        <img class="mobile" :src="appData.image_mobile" alt="Illustration Application Extracadabra" />
      </div>
      <div class="col-xs-12 col-md-5 col-lg-6 download">
        <span>{{ appData.title }}</span>
        <div>
          <a v-for="app in appData.apps" :id="'conversion_' + app.slug" :key="app.url" :href="app.url" target="_blank" :title="app.title">
            <img :src="app.logo" :alt="app.alt" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      appData: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style lang="scss">
  .app-banner-landing {
    background-color: #fff;
    height: 100%;
    display: flex;
    margin-top: -33px;
    .app-banner-landing-container {
      height: 266px;
      width: 100%;
      background-color: $secondary;
      @media screen and (min-width: $breakpoint-sm-max) {
        padding: 0 0 0 107px;
        height: 147px;
        justify-content: space-between;
      }
      @media screen and (min-width: $breakpoint-lg-min) {
        padding: 0 150px 0 215px;
        justify-content: inherit;
      }

      .illustration {
        position: relative;
        height: 73%;
        display: flex;
        justify-content: center;
        @media screen and (min-width: $breakpoint-sm-max) {
          justify-content: start;
          height: auto;
        }
        img {
          position: absolute;
          bottom: 0;
          @media screen and (min-width: $breakpoint-sm-max) {
            width: 100%;
            max-width: 580px;
          }
        }
      }
      .download {
        @media screen and (min-width: $breakpoint-sm-max) {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding-left: 0;
        }
        > span {
          text-transform: uppercase;
          font-family: "PoppinsBold";
          font-size: 24px;
          color: #fff;
          display: none;
          @media screen and (min-width: $breakpoint-sm-max) {
            display: inline-block;
            width: 100%;
          }
        }
        > div {
          display: flex;
          justify-content: center;
          @media screen and (min-width: $breakpoint-sm-max) {
            margin-top: -40px;
          }
          @media screen and (min-width: $breakpoint-lg-min) {
            justify-content: start;
          }
          & > a {
            margin-right: 22px;
            display: flex;
          }
        }
      }
      .desktop {
        display: none;
        @media screen and (min-width: $breakpoint-sm-max) {
          display: block;
        }
      }
      .mobile {
        display: block;
        max-width: 323px;
        @media screen and (min-width: $breakpoint-sm-max) {
          display: none;
        }
      }
    }
  }
</style>
