<template>
  <div v-scroll="handleScroll" class="extracadabra-base-header landing">
    <QHeader elevated>
      <QToolbar>
        <router-link to="/" class="extracadabra-base-header-logo">
          <img :src="require('@core/assets/img/logo_extracadabra_full_secondary.svg')" :draggable="false" alt="logo extracadabra" class="q-py-xs" />
        </router-link>
        <button v-if="showButton && cta !== null" :id="'conversion_' + cta.slug + '_header'" class="cta-header" @click="openModal(cta.url)">
          {{ cta.text }}
        </button>
      </QToolbar>
    </QHeader>
  </div>
</template>

<script>
  export default {
    props: {
      cta: {
        type: Object,
        default: () => ({}),
      },
    },
    emits: ["openModalForm"],
    data() {
      return {
        showButton: false,
        timeoutId: null,
      };
    },
    methods: {
      handleScroll() {
        const heroBanner = document.getElementById("conversion_cta");
        if (heroBanner) {
          const rect = heroBanner.getBoundingClientRect();
          if (rect.bottom < 0) {
            if (!this.showButton) {
              this.timeoutId = setTimeout(() => {
                this.showButton = true;
              }, 100);
            }
          } else if (this.showButton) {
            clearTimeout(this.timeoutId);
            this.showButton = false;
          }
        }
      },
      openModal(url) {
        this.$emit("openModalForm", url);
      },
    },
  };
</script>
<style lang="scss">
  .cta-header {
    width: 200px;
    border-radius: 25px;
    height: 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "PoppinsBold";
    text-transform: uppercase;
    padding: 17px 0;
    border: 1px solid transparent;
    transition: all ease-in-out 0.2s;
    border-color: $secondary;
    color: #fff;
    background-color: $secondary;
    @media screen and (min-width: $breakpoint-lg-min) {
      margin-right: 140px;
    }
    &:hover {
      box-shadow: 0px 0px 3px 0px #000;
      transition: all ease-in-out 0.2s;
      cursor: pointer;
    }
  }
</style>
