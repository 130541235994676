const hero = {
  title: '<span class="secondary">Travaille</span> au rythme<br /><span class="secondary">de tes envies à Lyon</span>',
  subtitle:
    "Inscris-toi et reçois toutes <strong>nos offres Extra, CDI et intérim.</strong> Les meilleurs jobs en <strong>Salle, cuisine et vente</strong> sont chez Extracadabra !",
  bg_mob: "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/hidf-lyon/background-hero-banner-mobile.png",
  bg_desk: "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/hidf-lyon/background-hero-banner-desktop.png",
  cta: [
    {
      color: "secondary",
      text: "Rejoins-nous",
      url: "https://extracadabra-paris.typeform.com/to/LNp7v6tc",
      slug: "demo",
    },
  ],
};

const app = {
  title: "Téléchargez l’app extracadabra",
  image_mobile: "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/hidf-lyon/phone_app_mobile.png",
  image_desktop: "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/hidf-lyon/phone_app_desktop.png",
  apps: [
    {
      logo: "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/playstore_logo.png",
      url: "https://play.google.com/store/apps/details?id=com.extracadabra.android.extra",
      title: "Play store",
      alt: "Play store logo",
      slug: "play_store",
    },
    {
      logo: "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/applestore_logo.png",
      url: "https://apps.apple.com/fr/app/extracadabra-etablissement/id1056999788",
      title: "Apple store",
      alt: "Apple store logo",
      slug: "apple_store",
    },
  ],
};

const verbatim = {
  title: "Nos extras en parlent mieux que nous !",
  verbatim: [
    {
      position: "left",
      text: "<figure><blockquote><p>“L’application est vraiment simple d’utilisation. Ca me permet de passer de restaurant en restaurant pour voir ce qui me correspond le plus car j’aime bien bouger. Les missions tombent toutes seules c’est génial !”</p></blockquote></figure>",
      author: "<strong>Seraphin, 30 ans, Chef de rang</strong>",
      avatar: "https://staticadabra.s3.amazonaws.com/recrutement/marketing/landing-page/hidf-lyon/seraphin.png",
      image_alt: "Avatar de Seraphin",
    },
    {
      position: "right",
      text: "<figure><blockquote><p>”Cela fait quelques mois que j’utilise l’app, je cherchais à être plus indépendante. Extracadabra me donne beaucoup de liberté, je choisis où et quand je veux travailler. J’accepte les missions que je veux, ça me permet d’être flexible. Il y a aussi un très bon suivi après ta mission”</p></blockquote></figure>",
      author: "<strong>Chloé, 27 ans, Chef de partie</strong>",
      avatar: "https://staticadabra.s3.amazonaws.com/recrutement/marketing/landing-page/hidf-lyon/chloe.png",
      image_alt: "Avatar de Chloe",
    },
  ],
};

const whyExtracadabra = {
  title: "Trouver une mission n’a jamais été aussi simple !",
  subtitle: "<p>De la création de compte au paiement, Extracadabra t’accompagne tout au long de ta mission.</p>",
  background:
    "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/illustration_bg_desktop_why_extra.png",
  list_step: [
    {
      text: "Crée ton profil en 2 minutes. Complète tes expériences, tes compétences, tes critères de recherche. Définit comment tu veux travailler !",
    },
    {
      text: "Reçois des propositions. Tous les jours, nous t’envoyons des dizaines d’offres ciblées adaptées à ton profil et tes envies.",
    },
    {
      text: "Travaille quand tu veux. Postule ou refuse une offre en fonction de tes critères et de tes disponibilités. ",
    },
  ],
};

const reinforcement = {
  title: "UNE SOLUTION SIMPLE ET RAPIDE POUR RENFORCER VOS ÉQUIPES EN RESTAURATION COLLECTIVE",
  subtitle:
    "<p>Travailler avec Extracadabra, c’est travailler sereinement ! De nombreuses fonctionnalités et avantages sont disponibles en passant par nos services :</p>",
  background:
    "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/illustration_bg_desktop_reinforcement.png",
  list_step: [
    {
      title: "UN PLANNING",
      text: "Vous disposez d’une vue depuis votre backoffice sur l’ensemble du planning des extras en mission",
    },
    {
      title: "LIBRE DE VOTRE CHOIX",
      text: "Vous avez le choix de l'extra avec une visibilité de son CV, de ses expériences et des avis. Il est par ailleurs simple de travailler par la suite avec vos candidats favoris",
    },
    {
      title: "ACCÉS MULTICOMPTE",
      text: "Un accès multicompte pour gérer plusieurs établissements à la fois",
    },
    {
      title: "UN TARIF GARANTI",
      text: "Il n’y a pas de coefficient de tension appliqué",
    },
    {
      title: "ASSURANCE AXA",
      text: "Nos Extras sont couverts par AXA et bénéficient d’une prévoyance",
    },
    {
      title: "DES CANDIDATS QUALIFIÉS",
      text: "Nous effectuons une pré-qualification pour nous assurer de vous proposer des talents compétents sur des postes à forte valeur ajoutée.",
    },
  ],
};

const interimOffer = {
  title: "Trouver une mission n’a jamais été aussi simple !",
  // subtitle: "<p>Extracadabra propose désormais une offre dédiée à l'intérim. Retrouvez vos candidats préférés, toujours aussi qualifiés, mais sous contrat d'intérim. En plus de nos avantages habituels (système de matching, contact opérationnel, sourcing personnalisé...), bénéficiez :</p>",
  image_desktop:
    "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/illustration_interim.png",
  image_mobile:
    "https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/illustration_bg_mobile_why_extra.png",
  isLarge: true,
  list_step: [
    {
      text: "Crée ton profil en 2 minutes. Complète tes expériences, tes compétences, tes critères de recherche. Définit comment tu veux travailler !",
    },
    {
      text: "Reçois des propositions. Tous les jours, nous t’envoyons des dizaines d’offres ciblées adaptées à ton profil et tes envies. ",
    },
    {
      text: "Travaille quand tu veux. Postule ou refuse une offre en fonction de tes critères et de tes disponibilités.",
    },
  ],
};

const listPictos = {
  title: "Rejoins notre communauté de talents à Lyon",
  pictos: [
    {
      picto: "https://staticadabra.s3.amazonaws.com/recrutement/marketing/landing-page/hidf-lyon/picto-conseiller.png",
      alt: "Picto conseiller",
      text: "Une équipe dédiée pour t’accompagner avant, pendant et après ta mission.",
    },
    {
      picto: "https://staticadabra.s3.amazonaws.com/recrutement/marketing/landing-page/hidf-lyon/picto-axa.png",
      alt: "Picto axa",
      text: "Une assurance Axa : En tant qu’auto-entrepreneur, tu es assuré gratuitement par Extracadabra auprès d’AXA.",
    },
    {
      picto: "https://staticadabra.s3.amazonaws.com/recrutement/marketing/landing-page/hidf-lyon/picto-facturation.png",
      alt: "Picto facturation",
      text: "Rémunération : reçois ta paye tous les 15 jours et garde l’esprit tranquille !",
    },
  ],
};

const faq = [
  {
    question: "Quels sont les avantages à devenir auto-entrepreneur chez Extracadabra ?",
    answer:
      "<p>En tant qu’auto-entrepreneur, tu recevras l’intégralité des missions proposées sur l’application.<br /><br />Tu es ton propre patron et tu peux ainsi choisir de travailler au rythme de tes envies.<br /><br />Reçois ta paie tous les 15 jours par virement bancaire et les factures par email.</p>",
  },
  {
    question: "Suis-je assuré durant ma mission ?",
    answer:
      "<p>Bien sûr ! Extracadabra t’assure gratuitement auprès de son partenaire AXA.<br /><br /><u>Assurance Responsabilité civile professionnelle :</u><br />L’assurance **AXA** te couvre en cas de dommages corporels, matériels et immatériels causés à tes clients ou à toutes autres personnes dans le cadre d’une prestation effectuée avec nous.<br /><br /><u>Prévoyance santé :</u><br />Ce contrat te couvre en cas d’arrêt de travail. Quel que soit l’événement, l’objectif de la prévoyance est d’assurer le maintien de tes revenus. grâce à notre partenaire, tu es automatiquement couvert pendant toute la durée de ta mission, mais aussi sur le trajet aller et retour (dans la limite de 60 minutes pour chaque trajet).</p>",
  },
  {
    question: "Quelles sont les étapes pour créer mon compte ?",
    answer:
      "<p>Tout d’abord crée ton profil sur notre application ou en ligne. <br />Renseigne bien les informations suivantes :</p><ul><li>Ta présentation personnelle</li><li>Tes expériences professionnelles ainsi que leurs références</li><li>Tes compétences, tes formations et tes langues parlées</li><li>Tes critères de recherche</li><li>Tes disponibilités</li></ul><p>Cela te permettra d’avoir un profil validé rapidement.<br />En complétant au maximum ton profil, tu pourras postuler aux offres à long terme (CDI et Saisonnier) ainsi qu'aux missions ponctuelles.<br /><br />Par la suite, si tu souhaites effectuer des missions en tant qu’auto-entrepreneur, tu devras ajouter les documents administratifs demandés.</p>",
  },
];

export class HidfLyonService {
  static getHeroBanner() {
    return hero;
  }

  static getAppBanner() {
    return app;
  }

  static getVerbatim() {
    return verbatim;
  }

  static getInterimOffer() {
    return interimOffer;
  }

  static getWhyExtracadabra() {
    return whyExtracadabra;
  }

  static getReinforcement() {
    return reinforcement;
  }

  static getListPictos() {
    return listPictos;
  }

  static getFaq() {
    return faq;
  }
}
