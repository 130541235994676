<template>
  <div
    class="jobs-offer-card-desktop cursor-pointer"
    :class="['jobs-offer-card-desktop-theme-' + offer.getContractTypeColor()]"
    @click="getOfferLink()"
  >
    <header class="q-mb-sm row justify-between">
      <ExtraTag :label="$t(offer.getContractTypeDisplay())" :background="offer.getContractTypeColor()" />
    </header>
    <QCard :id="offer.getId()">
      <div class="jobs-offer-card-desktop-header relative-position" :style="backgroundImg" />
      <QCardSection class="q-pa-none row jobs-offer-card-body">
        <div class="jobs-offer-card-desktop-left-panel">
          <section class="row">
            <p class="text-bold ellipsis">
              <QIcon size="16px" name="fad fa-briefcase" />
              {{ offer.getQualification() }}
            </p>
          </section>
          <section>
            <p v-if="offer.getEtab().getName()" class="text-bold ellipsis">
              <QIcon size="16px" name="fad fa-store" />
              {{ offer.getEtab().getName() }}
            </p>
            <p class="ellipsis">
              <QIcon size="16px" :name="offer.getEtab().getName() ? '' : 'fad fa-store'" />
              {{ offer.getEtab().getCategory() }}
            </p>
          </section>
          <section>
            <p class="text-bold ellipsis">
              <QIcon size="16px" name="fad fa-map-marker-alt" />
              {{ offer.getPostalCode() }} {{ offer.getCity() }}
            </p>
          </section>
          <section>
            <div class="row justify-between">
              <section class="col-6">
                <p class="ellipsis">
                  <QIcon size="16px" name="far fa-calendar" />
                  {{ startLabel }}
                </p>
                <p class="text-bold ellipsis">
                  {{ offer.getStartDateDisplay() }}
                </p>
                <p v-if="offer.isTemporary()" class="text-bold ellipsis">
                  {{ offer.getTypicalSchedule().getSchedule() }}
                </p>
              </section>

              <section class="col-6 text-right">
                <!-- AE Duration -->
                <template v-if="offer.isTemporary()">
                  <p class="ellipsis">
                    <QIcon size="16px" name="far fa-clock" />
                    {{ $t("CORE.COMPONENTS.OFFERS.@DURATION") }}
                  </p>
                  <p v-if="offer.getDuration() !== undefined" class="text-bold">
                    {{ offer.getDuration() }}
                  </p>
                </template>
                <!-- Permanent Duration -->
                <template v-if="offer.isPermanent()">
                  <p v-if="offer.getDuration() !== undefined || nbDays" class="ellipsis">
                    <QIcon size="16px" name="far fa-clock" />
                    {{ $t("CORE.COMPONENTS.OFFERS.@DURATION_LONG") }}
                  </p>
                  <p v-if="offer.getDuration() !== undefined" class="text-bold ellipsis">
                    {{ offer.getDuration() }}
                  </p>
                  <p v-if="offer.isSeason()" class="text-bold ellipsis">
                    {{ nbDays }}
                  </p>
                </template>
              </section>
            </div>
          </section>
        </div>

        <div class="jobs-offer-card-desktop-right-panel">
          <section class="text-right q-pa-sm">
            <!-- Permanent Salary -->
            <template v-if="offer.isPermanent()">
              <section>
                <div class="row wrap justify-end">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span class="text-secondary text-bold">{{ salaryDisplay }}</span>
                  <span v-if="periodicity" class="text-subtitle2 q-ml-sm text-grey-5">{{ periodicity }}</span>
                </div>
              </section>
            </template>
            <!-- AE Salary -->
            <template v-if="offer.isAE()">
              <section>
                <div>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span class="text-secondary text-bold" v-html="salaryDisplay" />
                  <p class="text-subtitle2 text-grey-5">
                    {{ $t("CORE.COMPONENTS.OFFERS.@SALARY_AE_TOTAL") }}
                    <br />
                    {{ offer.getSalary().getForecastDisplay() }}
                  </p>
                </div>
              </section>
            </template>
            <!-- INTERIM Salary -->
            <template v-if="offer.isInterim()">
              <section>
                <div>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span class="text-secondary text-bold" v-html="salaryDisplay" />
                  <p class="text-subtitle2 text-grey-5">
                    {{ $t("CORE.COMPONENTS.OFFERS.@SALARY_INTERIM_TOTAL") }}
                    <br />
                    {{ offer.getSalary().getForecastDisplay() }}
                  </p>
                </div>
              </section>
            </template>
          </section>

          <section class="text-center q-gutter-sm q-pr-sm q-pb-xs">
            <QBtn
              class="btn-large font-095"
              dense
              :label="$t('CORE.COMPONENTS.OFFERS.@GO_TO_OFFER')"
              color="secondary"
              no-caps
              @click="getOfferLink()"
            />
          </section>
        </div>
      </QCardSection>
    </QCard>
    <div class="row justify-between">
      <span>&nbsp;</span>
      <span class="font-080 text-grey-5 text-italic q-pa-sm">
        {{ $t("CORE.COMPONENTS.OFFERS.@PUBLICATION_DATE", { date: offer.getPublicationDateFromNow() }) }}
      </span>
    </div>
  </div>
</template>

<script>
  import { JobOfferDetailModel } from "@core/models";
  import { CANDIDATES_OFFER_STATUS } from "@core/constants";
  import { JOBS_ROUTES } from "@jobs/config";

  export default {
    props: {
      offer: {
        type: Object,
        default: () => new JobOfferDetailModel(),
      },
    },
    computed: {
      showIsFluctuating() {
        return this.offer.getSalary().isFluctuating() && !this.offer.getSalary().hasRange();
      },
      periodicity() {
        const { offer } = this;
        const periodicity = offer.getSalary().getPeriodicity();
        return !this.showIsFluctuating ? periodicity?.toLowerCase() : null;
      },
      salaryDisplay() {
        const { offer } = this;
        const salary = offer.getSalary().getAmountDisplay();
        if (offer.isTemporary()) {
          return this.$t("CORE.COMPONENTS.JOB_OFFER.@SALARY_TEMPORARY", { salary });
        }
        return this.showIsFluctuating
          ? this.$t("CORE.COMPONENTS.JOB_OFFER.@FLUCTUATING")
          : this.$t("CORE.COMPONENTS.JOB_OFFER.@SALARY_PERMANENT_CARD", { salary });
      },
      isPermanentOffer() {
        return this.offer.isPermanent();
      },
      shouldDisplayTimeSheetButtons() {
        return (
          ((this.offer.isCanceledMissionStatus() || this.offer.isSelectedStatus()) && this.offer.getActivityReportUrl()) || this.offer.isReviewable()
        );
      },
      startLabel() {
        return this.isPermanentOffer ? this.$t("CORE.COMPONENTS.OFFERS.@CONTRACT_START_DATE") : this.$t("CORE.COMPONENTS.OFFERS.@START_DATE_LONG");
      },
      backgroundImg() {
        return { backgroundImage: `url(${this.offer.getPicture()})` };
      },
      nbDays() {
        const nbDaysDisplay = this.$tc("CORE.COMPONENTS.OFFERS.@NB_DAYS", this.offer.getNbDays());
        return this.offer.getNbDays() > 0 ? `${nbDaysDisplay}` : "";
      },
    },
    created() {
      this.CANDIDATES_OFFER_STATUS = CANDIDATES_OFFER_STATUS;
    },
    mounted() {
      this.preventMiddleClickScroll();
    },
    methods: {
      preventMiddleClickScroll() {
        const element = this.$el;
        element.onmousedown = (event) => {
          const isMiddleClick = event.button === 1;
          if (isMiddleClick) {
            return false; // this will prevent the middle click scroll behavior
          }
          return true;
        };
      },
      getOfferLink() {
        // get the route query params only if it contains utm params
        const query = this.$route.query;
        const utmQueryParams = Object.keys(query).reduce((obj, key) => {
          if (key.includes("utm")) {
            obj[key] = query[key];
          }
          return obj;
        }, {});

        const encodedSlug = encodeURIComponent(this.offer.getUrl());
        this.$router
          .push({ name: JOBS_ROUTES.public.offer.detail.name, query: utmQueryParams, params: { slug: encodedSlug } })
          .catch((suppressedError) => {});
      },
    },
  };
</script>

<style lang="scss">
  .jobs-offer-card-desktop {
    width: 100%;
    max-width: 600px;

    .q-card {
      border-radius: 0 0 15px 15px;
      overflow: hidden;
    }

    .jobs-offer-card-desktop-header {
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 150px;
      display: flex;
      justify-content: flex-end;
      .jobs-offer-card-desktop-header-overlay {
        width: 35%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }

    .jobs-offer-card-desktop-icon {
      width: 25px !important;
      min-width: 25px !important;
    }

    .jobs-offer-card-desktop-left-panel,
    .jobs-offer-card-desktop-right-panel {
      padding: 8px 16px;
      height: 210px;
    }

    .jobs-offer-card-desktop-left-panel {
      width: 65%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      > section {
        padding: 5px 0;
        p {
          padding-left: 24px;
          margin-bottom: 0;
          position: relative;
          .q-icon {
            position: absolute;
            left: 0;
            top: 3px;
          }
        }
        .text-right {
          p {
            padding-left: auto;
            margin-bottom: 0;
            .q-icon {
              position: relative;
              left: auto;
              top: auto;
              margin-top: 1px;
              margin-right: 4px;
            }
          }
        }
      }
    }

    .jobs-offer-card-desktop-right-panel {
      width: 35%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .jobs-offer-card-desktop-theme-secondary {
    .q-badge {
      background-color: $secondary;
    }
    .jobs-offer-card-desktop-left-panel {
      background-color: rgba($secondary, 0.15);
    }
    &.jobs-offer-card-booking-favorite {
      .jobs-offer-card-desktop-left-panel {
        background: none;
        border-right: 1px solid rgba($secondary, 0.15);
      }
    }
  }

  .jobs-offer-card-desktop-theme-primary {
    .q-badge {
      background-color: $primary;
    }
    .jobs-offer-card-desktop-left-panel {
      background-color: rgba($primary, 0.15);
    }
  }
  .jobs-offer-card-desktop-theme-season-orange {
    .q-badge {
      background-color: $season-orange;
    }
    .jobs-offer-card-desktop-left-panel {
      background-color: rgba($season-orange, 0.15);
    }
  }
  .jobs-offer-card-desktop-theme-interim-green {
    .q-badge {
      background-color: $interim-green;
    }
    .jobs-offer-card-desktop-left-panel {
      background-color: rgba($interim-green, 0.15);
    }
  }
</style>
