<template>
  <div
    class="hero-banner row"
    :style="{
      '--bg-image-mobile': `url(${heroData.bg_mob})`,
      '--bg-image-desktop': `url(${heroData.bg_desk})`,
    }"
  >
    <div class="container-bloc">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <h1 v-html="heroData.title" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-if="heroData.cta.length === 1" v-html="heroData.subtitle" />
      <div class="container-cta">
        <a
          v-for="cta in heroData.cta"
          :id="cta.color === 'secondary' ? 'conversion_cta' : null"
          :key="cta.text"
          :href="cta.url"
          :class="cta.color"
          @click.prevent="handleClick(cta)"
        >
          {{ cta.text }}
        </a>
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-if="heroData.cta.length > 1" v-html="heroData.subtitle" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      heroData: {
        type: Object,
        default: () => ({}),
      },
    },

    emits: ["openModalForm"],
    methods: {
      openModal(url) {
        this.$emit("openModalForm", url);
      },
      handleClick(cta) {
        if (cta.color === "secondary") {
          this.openModal(cta.url);
        } else {
          window.location.href = cta.url;
        }
      },
    },
  };
</script>

<style lang="scss">
  .hero-banner {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 300px;
    background-image: var(--bg-image-mobile), linear-gradient(180deg, #fce6d5 0%, #fff 100%);

    @media screen and (min-width: $breakpoint-md-min) {
      background-image: var(--bg-image-desktop), linear-gradient(180deg, #fce6d5 0%, #fff 100%);
    }

    @media screen and (min-width: $breakpoint-sm-max) {
      padding-left: 120px;
      height: 525px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    @media screen and (min-width: $breakpoint-lg-min) {
      padding-left: 237px;
      background-size: cover;
    }
    .container-bloc {
      max-width: 385px;
      padding: 0 14px;
      margin: 0 auto;
      @media screen and (min-width: $breakpoint-sm-max) {
        max-width: 570px;
        padding: 0;
        margin: 0;
      }
      h1 {
        position: relative;
        padding: 0 20px;
        text-align: center;
        font-size: 28px;
        margin: 0 auto;
        color: $primary;
        font-family: "PoppinsBold";
        margin-top: 25px;
        @media screen and (min-width: $breakpoint-sm-max) {
          text-align: left;
          font-size: 36px;
          margin: 40px 0 0 0;
          height: fit-content;
          padding-right: 56px;
          padding-left: 0;
        }
        .secondary {
          color: $secondary;
          display: block;
          @media screen and (min-width: $breakpoint-sm-max) {
            display: inline;
          }
        }
        &:before {
          content: "";
          display: block;
          position: absolute;
          bottom: 8px;
          left: 31px;
          width: 24px;
          height: 20px;
          background-image: url("https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/xtra_stars_small_left.svg");
          background-repeat: no-repeat;
          @media screen and (min-width: $breakpoint-sm-max) {
            display: none;
          }
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 8px;
          right: 33px;
          width: 24px;
          height: 20px;
          background-image: url("https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/xtra_stars_small_right.svg");
          @media screen and (min-width: $breakpoint-sm-max) {
            background-image: url("https://staticadabra.s3.eu-west-3.amazonaws.com/recrutement/marketing/landing-page/restauration-collective/xtra_stars.svg");
            right: 10px;
            width: 38px;
            height: 32px;
          }
        }
      }
      .container-cta {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;
        @media screen and (min-width: $breakpoint-sm-max) {
          justify-content: start;
          margin-top: 40px;
        }
        a {
          width: 82%;
          border-radius: 25px;
          height: 36px;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "PoppinsBold";
          text-transform: uppercase;
          padding: 25px 0;
          border: 1px solid transparent;
          transition: all ease-in-out 0.2s;
          line-height: normal;
          @media screen and (min-width: $breakpoint-sm-max) {
            width: 51%;
          }
          &:hover {
            box-shadow: 0px 0px 3px 0px #000;
            transition: all ease-in-out 0.2s;
            cursor: pointer;
          }
          &.secondary {
            color: #fff;
            background-color: $secondary;
          }
          &.transparent {
            border-color: $secondary;
            color: $secondary;
            margin-top: 15px;
          }
        }
      }
      p {
        text-align: center;
        color: $primary;
        font-family: "Poppins";
        font-size: 18px;
        margin-top: 20px;
        @media screen and (min-width: $breakpoint-sm-max) {
          text-align: left;
          max-width: 446px;
        }
        @media screen and (min-width: $breakpoint-lg-min) {
          max-width: 485px;
        }
        strong {
          font-family: "PoppinsBold";
          &.secondary {
            color: $secondary;
          }
        }
      }
    }
  }
</style>
