<template>
  <BaseLandingFooter>
    <div class="col-left">
      <img :src="require('@core/assets/img/logo_extracadabra_full.svg')" :draggable="false" alt="logo extracadabra" class="q-py-xs" />
      <div class="links">
        <a href="https://www.extracadabra.com/confidentiality" target="_blank" title="Confidentialité">Confidentialité</a>
        |
        <a href="https://www.extracadabra.com/cgu" target="_blank" title="CGU">CGU</a>
      </div>
    </div>
    <div class="col-right">
      <span>TÉLÉCHARGEZ L’APP EXTRACADABRA</span>
      <div>
        <a v-for="app in appList" :key="app.url" :href="app.url" :title="app.title" target="_blank">
          <img :src="app.logo" :alt="app.alt" />
        </a>
      </div>
    </div>
  </BaseLandingFooter>
</template>

<script>
  import BaseLandingFooter from "@core/components/layouts/BaseLanding.footer.vue";

  export default {
    components: {
      BaseLandingFooter,
    },
    props: {
      appList: {
        type: Array,
        required: true,
      },
    },
  };
</script>
<style lang="scss">
  .col-left {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    @media screen and (min-width: $breakpoint-sm-max) {
      width: 50%;
      justify-content: flex-start;
    }
    img {
      width: 120px;
      height: auto;
    }
    .links {
      margin-top: 5px;
      width: 100%;
      display: flex;
      justify-content: center;
      @media screen and (min-width: $breakpoint-sm-max) {
        justify-content: flex-start;
      }
      a {
        color: #fff;
        text-decoration: none;
        font-size: 12px;
        margin: 0 10px;
      }
    }
  }
  .col-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    @media screen and (min-width: $breakpoint-sm-max) {
      width: 50%;
      justify-content: end;
      margin-top: 0;
    }
    span {
      font-family: "PoppinsBold";
    }
    div {
      display: flex;
      width: 100%;
      justify-content: center;
      @media screen and (min-width: $breakpoint-sm-max) {
        justify-content: end;
      }
    }
  }
</style>
