<template>
  <div class="base-footer-component landing">
    <QFooter>
      <QToolbar color="primary">
        <slot />
      </QToolbar>
    </QFooter>
  </div>
</template>

<script>
  export default {
    name: "BaseLandingFooter",
  };
</script>

<style lang="scss">
  .base-footer-component.landing {
    @media screen and (min-width: $breakpoint-sm-max) {
      height: 147px;
    }
    .q-layout__section--marginal {
      padding: 20px 0;
      background-color: $secondary;
      height: 100%;
      display: flex;
      @media screen and (min-width: $breakpoint-sm-max) {
        padding: 0;
      }
    }
    .q-footer > .q-toolbar {
      flex-wrap: wrap;
      justify-content: center;
      @media screen and (min-width: $breakpoint-sm-max) {
        justify-content: space-between;
      }
    }
  }
</style>
