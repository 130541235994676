<template>
  <BaseFooter>
    <section class="col-md col-xs-12">
      <span class="title-footer">
        {{ $t("JOBS.LABELS.@ABOUT") }}
      </span>
      <ul>
        <li>
          <a class="job-footer-item q-py-sm" :href="CGU_URL" target="__blank">{{ $t("JOBS.LABELS.@LINK_CGU") }}</a>
        </li>
        <li>
          <a class="job-footer-item q-py-sm" :href="CONFIDENTIALITY_URL" target="__blank">{{ $t("JOBS.LABELS.@LINK_CONFIDENTIALITY") }}</a>
        </li>
        <li>
          <a class="job-footer-item q-py-sm" :href="BLOG_URL" target="__blank">{{ $t("JOBS.LABELS.@LINK_BLOG") }}</a>
        </li>
        <li>
          <a class="job-footer-item q-py-sm" :href="FAQ_CANDIDATES_URL" target="__blank">{{ $t("JOBS.LABELS.@LINK_FAQ") }}</a>
        </li>
        <li>
          <router-link class="job-footer-item q-py-sm" :to="JOBS_ROUTES.public.hidfLyon.path">
            {{ $t("JOBS.LABELS.@LINK_LANDING_HIDF") }}
          </router-link>
        </li>
        <li>
          <router-link class="job-footer-item q-py-sm" :to="JOBS_ROUTES.public.vendeurSpecialise.path">
            {{ $t("JOBS.LABELS.@LINK_LP_SELLER") }}
          </router-link>
        </li>
      </ul>
    </section>
    <section class="col-md col-xs-12 q-mb-md">
      <span class="title-footer footer-question-link">
        <a class="text-white text-bold" :href="BLOG_QUESTIONS_URL" target="__blank"> {{ $t("JOBS.LABELS.@QUESTION") }}</a>
      </span>
    </section>
    <section class="col-md col-xs-12">
      <span class="title-footer">
        {{ $t("JOBS.LABELS.@FOLLOW_US") }}
      </span>
      <SocialMediaComponent />
    </section>
    <section class="col-md col-xs-12">
      <span class="title-footer text-center">
        {{ $t("JOBS.LABELS.@DOWNLOAD_APP") }}
      </span>
      <StoresComponent candidate />
    </section>
  </BaseFooter>
</template>

<script>
import {
  EXTRACADABRA_URL,
  BLOG_URL,
  FAQ_CANDIDATES_URL,
  BLOG_QUESTIONS_URL,
  CGU_URL,
  CONFIDENTIALITY_URL,
  LP_LYON_URL,
  LP_SELLER_URL,
  EXTRA_APP_ID_IOS,
  EXTRA_APP_ID_ANDROID,
} from "@core/constants";
import { StoresService } from "@core/plugins/mobile";

import SocialMediaComponent from "@core/components/media/Social.component.vue";
import StoresComponent from "@core/components/media/Stores.component.vue";
import BaseFooter from "@core/components/layouts/Base.footer.vue";
import { JOBS_ROUTES } from "../config";

export default {
  name: "JobsFooter",
  components: {
    SocialMediaComponent,
    StoresComponent,
    BaseFooter,
  },
  created() {
    this.initLinks();
  },
  methods: {
    initLinks() {
      this.EXTRACADABRA_URL = EXTRACADABRA_URL;
      this.BLOG_URL = BLOG_URL;
      this.FAQ_CANDIDATES_URL = FAQ_CANDIDATES_URL;
      this.CGU_URL = CGU_URL;
      this.BLOG_QUESTIONS_URL = BLOG_QUESTIONS_URL;
      this.CONFIDENTIALITY_URL = CONFIDENTIALITY_URL;
      this.PLAY_STORE_LINK = StoresService.getAndroidStoreLink(EXTRA_APP_ID_ANDROID);
      this.APPLE_STORE_LINK = StoresService.getAppleStoreLink(EXTRA_APP_ID_IOS);
      this.LP_LYON_URL = LP_LYON_URL;
      this.LP_SELLER_URL = LP_SELLER_URL;
      this.JOBS_ROUTES = JOBS_ROUTES;
    },
  },
};
</script>

<style lang="scss">
.job-footer-item {
  font-size: 1rem;
}
.footer-question-link {
  text-align: left;
  @media screen and (max-width: $breakpoint-sm-max) {
    text-align: center;
  }
}
.title-footer {
  font-weight: bold;
  font-family: "Avenir";
  font-size: 18px;
  display: flex;
  &.text-center {
    justify-content: center;
  }
}
</style>
