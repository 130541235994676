<template>
  <QForm
    v-if="qualifications.length > 0 && contracts.length > 0"
    class="jobs-form-component q-py-md q-header-height"
    :class="shouldStick ? 'jobs-form-sticky' : ''"
    @submit="onSubmit"
  >
    <div v-if="!dense">
      <h1 class="text-center text-h2 q-pb-md">
        {{ $t("JOBS.SEARCH.@TITLE") }}
      </h1>
    </div>
    <div class="row">
      <div class="col-md-5 col-xs-12 q-pa-xs">
        <QSelect
          v-model="selectedQualifications"
          :options="filteredQualifications"
          :option-label="(opt) => opt.getLabel()"
          popup-content-class="desktop-combobox-size"
          hide-dropdown-icon
          filled
          bg-color="grey-3"
          multiple
          display-value-html
          use-input
          options-selected-class="text-accent"
          input-debounce="0"
          :label="$t('JOBS.LABELS.@QUALIFICATION')"
          :display-value="displayMultiSelectValue(selectedQualifications)"
          @filter="filterAutocomplete"
        >
          <template #option="scope">
            <QItem v-bind="scope.itemProps">
              <QItemSection>
                <QItemLabel>{{ scope.opt.getLabel() }}</QItemLabel>
                <QItemLabel caption>
                  {{ scope.opt.getFamilyLabel() }}
                </QItemLabel>
              </QItemSection>
            </QItem>
          </template>
        </QSelect>
      </div>

      <div class="col-md-2 col-xs-12 q-pa-xs">
        <QInput
          v-model.trim="selectedPostalCodes"
          :label="$t('JOBS.LABELS.@POSTAL_CODE')"
          filled
          :placeholder="$t('JOBS.SEARCH.@POSTALCODE_PLACEHOLDER')"
          bg-color="grey-3"
        />
      </div>

      <div class="col-md-5 col-xs-12 q-pa-xs">
        <div class="job-form-submit-wrapper">
          <div>
            <QSelect
              v-model="selectedContracts"
              :label="$t('JOBS.LABELS.@CONTRACT')"
              :options="contracts"
              :option-label="(opt) => $t(opt.getLabel())"
              :disable="hasForcedContracts"
              hide-dropdown-icon
              filled
              bg-color="grey-3"
              multiple
              options-selected-class="text-accent"
              display-value-html
              :display-value="displayMultiSelectValue(selectedContracts)"
            />
          </div>
          <div>
            <QBtn aria-label="search" icon="search" type="submit" color="secondary" size="lg" style="height: 56px" />
          </div>
        </div>
      </div>
    </div>
  </QForm>
</template>

<script>
  import { ArrayHelpers, JavascriptHelpers, QueryHelpers } from "@core/helpers";
  import { GETTER_JOBS_CONTRACTS_GET_ALL, GETTER_JOBS_QUALIFICATIONS_GET_ALL } from "@jobs/store/types";
  import { ContractModel, JobsSearchCriteriasModel, QualificationModel } from "@core/models";

  export default {
    name: "JobOfferSearch",
    props: {
      forcedCriterias: {
        type: Object,
        default: () => new JobsSearchCriteriasModel(),
      },
      dense: {
        type: Boolean,
        default: false,
      },
      shouldStick: {
        type: Boolean,
        default: false,
      },
      allowNewSearch: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["submit"],
    data() {
      return {
        selectedQualifications: [],
        selectedContracts: [],
        selectedPostalCodes: "",
        filteredQualifications: [],
        counterQualif: "",
      };
    },
    computed: {
      qualifications() {
        const qualifications = this.$store.getters[GETTER_JOBS_QUALIFICATIONS_GET_ALL];
        return qualifications.sort((a, b) => ArrayHelpers.alphabeticallySort(a.getLabel(), b.getLabel()));
      },
      contracts() {
        const contracts = this.$store.getters[GETTER_JOBS_CONTRACTS_GET_ALL];
        return contracts.sort((a, b) => ArrayHelpers.alphabeticallySort(a.getLabel(), b.getLabel()));
      },
      hasForcedQualifications() {
        return this.forcedCriterias.getQualificationsIds().length > 0;
      },
      hasForcedContracts() {
        return this.forcedCriterias.getContractsCodes().length > 0;
      },
      hasForcedPostalCodes() {
        return this.forcedCriterias.getPostalCodes().length > 0;
      },
    },
    watch: {
      $route(to, from) {
        this.preselectCriterias({ query: this.$route.query, forcedCriterias: this.forcedCriterias });
      },
    },
    mounted() {
      this.preselectCriterias({ query: this.$route.query, forcedCriterias: this.forcedCriterias });
    },
    methods: {
      preselectCriterias({ query = {}, forcedCriterias = new JobsSearchCriteriasModel() } = {}) {
        const selectedQualificationsIds = QueryHelpers.mergeQueryParams(query.q, forcedCriterias.getQualificationsIds());
        this.selectedQualifications = this.qualifications.filter((q) => selectedQualificationsIds.includes(q.getId().toString()));

        const selectedContractsCodes = QueryHelpers.mergeQueryParams(query.c, forcedCriterias.getContractsCodes());
        this.selectedContracts = this.contracts.filter((c) => selectedContractsCodes.includes(c.getCode()));

        const selectedPostalCodesIds = QueryHelpers.mergeQueryParams(query.cp, forcedCriterias.getPostalCodes());
        this.selectedPostalCodes = selectedPostalCodesIds.filter(Boolean).map(String).join(",");
      },
      filterAutocomplete(value, update, abort) {
        update(() => {
          const needle = JavascriptHelpers.removeDiacritics(value.toLowerCase());
          this.filteredQualifications = this.qualifications.filter(
            (qualification) =>
              JavascriptHelpers.removeDiacritics(qualification.getLabel().toLowerCase()).indexOf(needle) > -1 ||
              JavascriptHelpers.removeDiacritics(qualification.getFamilyLabel().toLowerCase()).indexOf(needle) > -1,
          );
        });
      },
      onSubmit() {
        const selectedQualifications = this.selectedQualifications.map((q) => new QualificationModel(q).getId());
        const selectedContracts = this.selectedContracts.map((c) => new ContractModel(c).getCode());
        const selectedPostalCodes = this.selectedPostalCodes;

        this.$emit("submit", { selectedQualifications, selectedContracts, selectedPostalCodes });
      },
      displayMultiSelectValue(multiSelectedArray = [], placeholder = "") {
        const selectedLength = multiSelectedArray.length;
        if (selectedLength > 0 && multiSelectedArray.every((item) => typeof item.getLabel === "function")) {
          const label = this.$t(multiSelectedArray[0].getLabel());
          if (selectedLength === 1) {
            return label;
          }
          if (selectedLength > 1) {
            const labelSbstr = label.length > 16 ? `${label.substr(0, 16)}...` : label;
            return `${labelSbstr} <span class='q-badge bg-blue'>+ ${selectedLength - 1}</span>`;
          }
        }
        return this.$t(placeholder);
      },
    },
  };
</script>

<style lang="scss">
  .jobs-form-component {
    @media screen and (min-width: $breakpoint-sm-max) {
      &.jobs-form-sticky {
        position: sticky;
        top: 50px;
        left: 0;
        z-index: 2;
        background-color: white;
        box-shadow: $hard-shadow;

        @media screen and (min-width: $breakpoint-sm-max) {
          // sticky on the side as well
          margin-left: -45px;
          margin-right: -45px;
        }
        @media screen and (min-width: $breakpoint-md-max) {
          // sticky on the side as well
          margin-left: -120px;
          margin-right: -120px;
        }
      }
    }

    .job-form-submit-wrapper {
      display: flex;
      > div:first-of-type {
        flex: 1;
      }
      > div:last-of-type {
        margin-left: 8px;
        width: 70px;
      }
    }
  }
</style>
